import React from 'react';
import { ContentLayout } from '@/components/Layout/ContentLayout';

export const ForbiddenFallback = () => {
  return (
    <ContentLayout>
      <div className="mt-8 flex justify-center">
        <h1 className="text-3xl font-bold text-emerald-400">無讀取權限</h1>
      </div>
    </ContentLayout>
  );
};
