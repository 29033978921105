import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider } from 'antd';
import { openDB, deleteDB } from 'idb';
import { Head } from '@/components/Head';
import { ContentLayout } from '@/components/Layout';
import { Authorization, ROLES } from '@/features/authorization';
import { ShowcaseSection, MedicineTable } from '@/features/medicineShowcase';
import { SearchBar, SearchHistory } from '@/components/Elements/SearchBar';
import { TreatmentTable } from '../components/TreatmentTable';
import { CustomizedTreatmentTable } from '../components/CustomizedTreatmentTable';
import {
  addSearchKeyword,
  removeSearchKeyword,
  updateSearchKeyword,
  loadTreatmentSearchHistory,
  setMode,
  setParentSegment,
  setChildSegment,
  addParentSegment,
  addChildSegment,
  updateSegments,
  removeSegment,
  updateSegmentShortcut,
  updateSections,
} from '../store/treatmentSearchSlice';
// import { fetchICDData } from '@/libs/firebase/database';
import { downloadTreatment } from '@/libs/firebase/storage';
// import { ControlPanel } from '@/features/searchControlPanel';
import { TableControlPanel } from '@/features/tableControlPanel';
import { PanelShortcuts } from '@/features/searchControlPanel/components/PanelShortcuts';
import { ControlPanelHeader } from './../../searchControlPanel/components/ControlPanelHeader';
import { loadRelatedWords } from '@/features/controlPanel/store/controlSlice';
import { fetchRelatedWordsData } from '@/libs/firebase/database';
import { TreatmentNavBar } from '../components/TreatmentNavBar';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Test } from '../components/Test';
import expandKeywords from '@/utils/expandKeywords';
// import { relatedWords } from '../relatedWords';

const transformKeywordGroup = (keywords) => {
  return keywords.map((keyword) => {
    if (keyword.includes('.')) {
      const cleanedKeyword = keyword.replaceAll(/\./g, '');

      return [keyword, cleanedKeyword];
    } else {
      return [keyword];
    }
  });
};

const searchTreatment = (treatmentData, keywordArr) => {
  const searchResult = treatmentData.reduce((acc, cur) => {
    let content = (
      cur['code'] +
      cur['item - Chinese'] +
      cur['item - English'] +
      ('' + cur['rule']) +
      ('' + cur['note']) +
      cur['relatedWords']
    ).toLowerCase();

    const hasAllKeyword = keywordArr.some((arr) => {
      return arr.every((keyword) => {
        // if keyword is english word, append space on front and end
        if (/^[a-zA-Z1-9\.]*$/.test(keyword)) {
          return content.includes(`${keyword}`);
        } else {
          return content.includes(keyword);
        }
      });
    });

    if (hasAllKeyword) {
      return [...acc, cur];
    }

    return acc;
  }, []);

  return searchResult;
};

const combineNote = (rawData, note) => {
  if (!rawData) return [];
  if (!note) return rawData;

  const combinedNote = rawData.map((treatment) => ({
    ...treatment,
    ...note?.[treatment.code],
  }));

  return combinedNote;
};

export const TreatmentSearch = React.memo(() => {
  const dispatch = useDispatch();
  const [downloadStatus, setDownloadStatus] = useState('');
  const [treatmentStructure, setTreatmentStructure] = useState([]);
  const [treatmentData, setTreatmentData] = useState([]);
  const [treatmentDataWithNote, setTreatmentDataWithNote] = useState([]);
  const [baseData, setBaseData] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [touched, setTouched] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const {
    treatment: treatmentVersion,
    IDB: IDBVersion,
    relatedWords: relatedWordsVersion,
  } = useSelector((state) => state.control.dataVersion);
  const relatedWords = useSelector((state) => state.control.relatedWords);
  const relatedWordsEnabled = useSelector(
    (state) => state.control.userPreference.relatedWordsEnabled,
  );
  const {
    sections,
    segments,
    searchHistory: keywords,
    searchControlPanel,
    mostUsed,
    pickedTreatment,
    myTreatmentNote,
  } = useSelector((state) => state.treatmentSearch);
  const { mode, currentSegment, segmentShortcuts } = searchControlPanel;
  const { parentSegment: parent, childSegment: child } = currentSegment;
  const isEditing = useSelector((state) => state.control.control.isEditing);

  useEffect(() => {
    const initTreatment = async () => {
      const medicalHelperDb = await openDB('medicalHelper', IDBVersion);
      const currentTreatmentVersion = await medicalHelperDb.get(
        'treatmentSearch',
        'treatmentVersion',
      );
      let treatmentStructure;
      let treatmentData;

      try {
        treatmentStructure = await medicalHelperDb.get(
          'treatmentSearch',
          'treatmentStructure',
        );
        treatmentData = await medicalHelperDb.get(
          'treatmentSearch',
          'treatmentData',
        );
      } catch (err) {
        (err) => console.log('No data in IDB');
      }

      const shouldUpdate = treatmentVersion > currentTreatmentVersion;

      if (!treatmentStructure || !treatmentData || shouldUpdate) {
        setDownloadStatus('資料下載中，請稍後...');

        treatmentStructure = await downloadTreatment({
          file: 'treatment_structure',
        });
        treatmentData = await downloadTreatment({ file: 'treatment' });

        await medicalHelperDb.put(
          'treatmentSearch',
          treatmentStructure,
          'treatmentStructure',
        );
        await medicalHelperDb.put(
          'treatmentSearch',
          treatmentData,
          'treatmentData',
        );
        await medicalHelperDb.put(
          'treatmentSearch',
          treatmentVersion,
          'treatmentVersion',
        );
      }

      setDownloadStatus('');
      setTreatmentStructure(treatmentStructure);
      setTreatmentData(treatmentData);
    };

    initTreatment();
  }, [treatmentVersion]);

  useEffect(() => {
    const initRelatedWords = async () => {
      const medicalHelperDb = await openDB('medicalHelper', IDBVersion);
      const currentRelatedWordsVersion = await medicalHelperDb.get(
        'general',
        'relatedWordsVersion',
      );
      let relatedWordsData;

      try {
        relatedWordsData = await medicalHelperDb.get(
          'general',
          'relatedWordsData',
        );
      } catch (err) {
        (err) => console.log('No data in medicines');
      }

      const shouldUpdate = relatedWordsVersion > currentRelatedWordsVersion;

      if (!relatedWordsData || shouldUpdate) {
        relatedWordsData = await fetchRelatedWordsData();

        await medicalHelperDb.put(
          'general',
          relatedWordsData,
          'relatedWordsData',
        );
        await medicalHelperDb.put(
          'general',
          relatedWordsVersion,
          'relatedWordsVersion',
        );
      }

      dispatch(loadRelatedWords(relatedWordsData));
    };

    initRelatedWords();
  }, [relatedWordsVersion]);

  useEffect(() => {
    const combinedNoteData = combineNote(treatmentData, myTreatmentNote);
    setTreatmentDataWithNote(combinedNoteData);

    switch (mode) {
      case 'ALL':
        setBaseData(combinedNoteData);

        break;
      case 'MOST_USED': {
        const pickedAndMostUsed = mostUsed.filter((code) =>
          pickedTreatment.includes(code),
        );
        const pickedAndMostUsedData = combinedNoteData.filter((treatment) =>
          pickedAndMostUsed.includes(treatment.code),
        );

        setBaseData(pickedAndMostUsedData);
        break;
      }
      case 'CUSTOMIZED': {
        if (!parent || !child) {
          setFinalData([]);
          break;
        }

        const parentSegment = segments.find(
          (pSegment) => pSegment.title === parent,
        );
        const childSegment = parentSegment?.childSegments?.find(
          (cSegment) => cSegment.title === child,
        );
        const relatedTreatment = childSegment?.relatedTreatment?.data || [];
        const pickedAndSegment = relatedTreatment?.filter((rCode) =>
          pickedTreatment.includes(rCode),
        );
        const pickedAndSegmentData = combinedNoteData.filter((treatment) =>
          pickedAndSegment.includes(treatment.code),
        );

        // NOTE: update relatedTreatment can not trigger useEffect to get latest pickedAndSegmentData,
        // Current solution: update the data inside <CustomizedTreatmentTable/> after remove treatment
        setBaseData(pickedAndSegmentData);
        setFinalData(pickedAndSegmentData);
        break;
      }
      case 'SECTION': {
        const currentStructure = sections.join('/');
        const sectionData = combinedNoteData.filter((treatment) =>
          treatment.structure.includes(currentStructure),
        );
        setBaseData(sectionData);
        break;
      }
      default:
        break;
    }
  }, [treatmentData, myTreatmentNote]);

  useEffect(() => {
    switch (mode) {
      case 'ALL':
        setBaseData(treatmentDataWithNote);
        setFinalData([]);
        break;
      case 'MOST_USED': {
        const pickedAndMostUsed = mostUsed.filter((code) =>
          pickedTreatment.includes(code),
        );
        const pickedAndMostUsedData = treatmentDataWithNote.filter(
          (treatment) => pickedAndMostUsed.includes(treatment.code),
        );

        setBaseData(pickedAndMostUsedData);
        setFinalData([]);
        break;
      }
      case 'CUSTOMIZED': {
        if (!parent || !child) {
          setFinalData([]);
          break;
        }

        const parentSegment = segments.find(
          (pSegment) => pSegment.title === parent,
        );
        const childSegment = parentSegment?.childSegments?.find(
          (cSegment) => cSegment.title === child,
        );
        const relatedTreatment = childSegment?.relatedTreatment?.data || [];
        const pickedAndSegment = relatedTreatment?.filter((rCode) =>
          pickedTreatment.includes(rCode),
        );
        const pickedAndSegmentData = treatmentDataWithNote.filter((treatment) =>
          pickedAndSegment.includes(treatment.code),
        );

        // NOTE: update relatedTreatment can not trigger useEffect to get latest pickedAndSegmentData,
        // Current solution: update the data inside <CustomizedTreatmentTable/> after remove treatment
        setBaseData(pickedAndSegmentData);
        setFinalData(pickedAndSegmentData);
        break;
      }
      case 'SECTION': {
        const currentStructure = sections.join('/');
        const sectionData = treatmentDataWithNote.filter((treatment) =>
          treatment.structure.includes(currentStructure),
        );
        setBaseData(sectionData);
        setFinalData(sectionData);
        break;
      }
      default:
        break;
    }

    if (mode !== 'SECTION' && sections.length > 0) {
      handleUpdateRoute({ sections: [] });
    }
  }, [mode, parent, child, sections]);

  const handleSetMode = useCallback(
    (mode) => {
      if (mode === 'CUSTOMIZED') {
        setKeyword('');
      } else {
        // clear current segment
        dispatch(setParentSegment(''));
        dispatch(setChildSegment(''));
      }
      dispatch(setMode(mode));
      setTouched(false);
    },
    [setMode],
  );

  const handleSetParentSegment = useCallback(
    (segment) => {
      dispatch(setParentSegment(segment));
      dispatch(setChildSegment(null));
    },
    [setParentSegment],
  );
  const handleSetChildSegment = useCallback(
    (segment) => {
      dispatch(setChildSegment(segment));
    },
    [setChildSegment],
  );
  const handleAddParentSegment = useCallback(
    (segment) => {
      dispatch(addParentSegment(segment));
    },
    [addParentSegment],
  );
  const handleAddChildSegment = useCallback(
    (segment) => {
      dispatch(addChildSegment(segment));
    },
    [addChildSegment],
  );
  const handleUpdateSegments = useCallback(
    (payload) => {
      dispatch(updateSegments(payload));
    },
    [updateSegments],
  );
  const handleRemoveSegment = useCallback(
    (payload) => {
      dispatch(removeSegment(payload));
    },
    [removeSegment],
  );
  const handleUpdateSegmentShortcut = useCallback(
    (payload) => {
      dispatch(updateSegmentShortcut(payload));
    },
    [updateSegmentShortcut],
  );
  const handleUpdateRoute = useCallback(
    ({ sections }) => {
      setKeyword('');
      dispatch(updateSections({ sections }));
    },
    [updateSections],
  );

  const handleSearch = (keyword) => {
    if (keyword !== '') {
      setTouched(true);
      dispatch(addSearchKeyword(keyword));

      let keywords = keyword.toLowerCase().split(' ');
      let keywordGroup = transformKeywordGroup(keywords);

      if (relatedWordsEnabled && Object.keys(relatedWords).length !== 0) {
        keywordGroup = keywordGroup.map((group) =>
          expandKeywords(group, relatedWords),
        );
      }

      // generate keywords combinations
      // let keywordGroupArray = [['A', 'a', '@'], ['B'], ['C.1', 'C1']];
      const keywordGroupArray = keywordGroup.reduce((acc, cur) => {
        return cur.flatMap((curKey) => {
          if (acc.length === 0) {
            return [[curKey]];
          } else {
            return acc.map((accKey) => {
              return [...accKey, curKey];
            });
          }
        });
      }, []);

      switch (mode) {
        case 'ALL':
          setFinalData(searchTreatment(baseData, keywordGroupArray));
          break;
        case 'MOST_USED': {
          // Update base data with mostUsed in case user have remove mostUsed treatment in the previous operation
          const updatedBaseData = baseData.filter((treatment) =>
            mostUsed.includes(treatment.code),
          );
          setFinalData(searchTreatment(updatedBaseData, keywordGroupArray));
          break;
        }
        case 'CUSTOMIZED':
          setFinalData(searchTreatment(baseData, keywordGroupArray));
          break;
        case 'SECTION':
          setFinalData(searchTreatment(baseData, keywordGroupArray));
          break;
      }
    }
  };

  const handleDisplayCustomized = ({ parent, child }) => {
    handleSetMode('CUSTOMIZED');
    handleSetParentSegment(parent);
    handleSetChildSegment(child);
  };

  const handleClick = (keyword) => {
    setKeyword(keyword);
    handleSearch(keyword);
  };

  const handleClose = (history) => {
    dispatch(removeSearchKeyword(history));
  };

  const removeHistory = () => {
    dispatch(updateSearchKeyword([]));
  };

  let content;

  if (finalData?.length > 0) {
    if (mode === 'ALL' || mode === 'SECTION') {
      content = <TreatmentTable treatmentData={finalData} />;
    } else {
      // MOST_USED, CUSTOMIZED
      content = (
        <CustomizedTreatmentTable
          treatmentData={finalData}
          mode={mode}
          currentSegment={currentSegment}
        />
      );
    }
  }

  if (finalData.length === 0 && touched && keyword) {
    content = <div className="p-5 text-center text-3xl">無搜尋結果</div>;
  }

  return (
    <>
      <Head title="處置及檢驗搜尋" description="藥方便，搜尋處置及檢驗" />
      <ContentLayout>
        <Authorization allowedRoles={[ROLES.ADMIN, ROLES.PREMIUM, ROLES.TRIAL]}>
          <div className="flex items-center gap-2 z-20">
            <ControlPanelHeader
              mode={mode}
              setMode={handleSetMode}
              currentSegment={currentSegment}
              segmentData={segments}
              setParentSegment={handleSetParentSegment}
              setChildSegment={handleSetChildSegment}
              addParentSegment={handleAddParentSegment}
              addChildSegment={handleAddChildSegment}
              updateSegments={handleUpdateSegments}
              removeSegment={handleRemoveSegment}
              handleDisplayCustomized={handleDisplayCustomized}
              isEditing={isEditing}
            />
            <TreatmentNavBar
              navData={treatmentStructure}
              handleClick={handleSetMode}
            />
          </div>
          <Breadcrumbs
            breadcrumbData={treatmentStructure}
            route={sections}
            updateRoute={handleUpdateRoute}
          />
          <div className="flex row justify-between pt-2">
            <div className="flex flex-col flex-1 items-center pt-4">
              <div className="w-11/12">
                <SearchBar
                  inputValue={keyword}
                  handleSearch={(keyword) => handleSearch(keyword)}
                  handleInput={setKeyword}
                  mode={mode}
                />
                <SearchHistory
                  historyData={keywords}
                  handleClick={handleClick}
                  handleClose={handleClose}
                  handleRemoveAll={removeHistory}
                  col={3}
                  mode="TREATMENT"
                />
              </div>
            </div>
            <div className="flex-1">
              <PanelShortcuts
                col={5}
                shortcutData={segmentShortcuts}
                currentSegment={currentSegment}
                segmentData={segments}
                updateSegmentShortcut={handleUpdateSegmentShortcut}
                handleDisplayCustomized={handleDisplayCustomized}
                isEditing={isEditing}
              />
            </div>
          </div>
          <Divider />
          {downloadStatus && (
            <div className="text-center text-2xl">{downloadStatus}</div>
          )}
          {content}
        </Authorization>
      </ContentLayout>
    </>
  );
});

TreatmentSearch.displayName = 'TreatmentSearch';
