import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Button, notification, Upload, Modal, Checkbox } from 'antd';
import { updateUserData } from '@/libs/firebase/database';
import { loadUserData as loadMedicine } from '@/features/medicines/store/medicinesSlice';
import { loadUserData as loadMyMedicine } from '@/features/myMedicines/store/myMedicinesSlice';
import { loadICDUserData } from '@/features/ICDSearch/store/ICDSearchSlice';
import { loadTreatmentUserData } from '@/features/treatmentSearch/store/treatmentSearchSlice';

const ImportModal = ({ isVisible, setVisible }) => {
  const dispatch = useDispatch();
  const [checkMedicine, setCheckMedicine] = useState(true);
  const [checkICD, setCheckICD] = useState(true);
  const [checkTreatment, setCheckTreatment] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadData, setUploadData] = useState();

  const settingMap = {
    medicineData: {
      checked: checkMedicine,
      title: '藥物設定',
    },
    ICDData: {
      checked: checkICD,
      title: '診斷碼設定',
    },
    treatmentData: {
      checked: checkTreatment,
      title: '處置及檢驗設定',
    },
  };

  const props = {
    name: 'file',
    maxCount: 1,
    beforeUpload(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // console.log(JSON.parse(e.target.result));
        setUploadData(JSON.parse(e.target.result));
      };
      reader.readAsText(file);

      return false;
    },
    onChange(info) {
      // console.log('info', info);
      // if (info.file.status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }

      if (info.file.status === 'done') {
        notification.success({
          message: '上傳結果',
          description: `${info.file.name} 上傳成功`,
          duration: 2,
        });
      } else if (info.file.status === 'error') {
        notification.error({
          message: '上傳結果',
          description: `${info.file.name} 上傳失敗`,
          duration: 2,
        });
      }
    },
  };

  const handleImport = () => {
    const missingData = [];

    Object.keys(settingMap).forEach((setting) => {
      if (settingMap[setting].checked && uploadData[setting] === false) {
        missingData.push(settingMap[setting].title);
      }
    });

    if (missingData.length > 0) {
      notification.error({
        message: '無相關欄位',
        description: `上傳檔案中無 ${missingData.join(' ')}`,
        duration: 2,
      });
      return;
    }

    const loadPromise = [];

    if (checkMedicine) {
      loadPromise.push(dispatch(loadMedicine(uploadData.medicineData)));
      loadPromise.push(dispatch(loadMyMedicine(uploadData.medicineData)));
    }
    if (checkICD) {
      loadPromise.push(
        dispatch(loadICDUserData({ ICDUserData: uploadData.ICDData })),
      );
    }
    if (checkTreatment) {
      loadPromise.push(
        dispatch(
          loadTreatmentUserData({
            treatmentUserData: uploadData.treatmentData,
          }),
        ),
      );
    }

    console.log(loadPromise);

    const updateData = async () => {
      await Promise.all(loadPromise)
        .then(() => {
          const successMessage = [];

          Object.keys(settingMap).forEach((setting) => {
            if (settingMap[setting].checked) {
              successMessage.push(settingMap[setting].title);
            }
          });

          notification.success({
            message: '匯入成功',
            description: `成功匯入 ${successMessage.join(' ')}`,
            duration: 2,
          });
          setLoading(false);
          setVisible(false);
        })
        .catch((err) => {
          notification.error({
            message: '匯入失敗',
            description: `${err}`,
            duration: 2,
          });
        });
    };
    setLoading(true);
    updateData();
  };

  return (
    <Modal
      open={isVisible}
      title="匯入個人資料"
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          disabled={
            !uploadData || (!checkMedicine && !checkICD && !checkTreatment)
          }
          onClick={handleImport}>
          匯入
        </Button>,
      ]}>
      <div className="flex flex-col items-center">
        <h3>上傳個人資料</h3>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        {uploadData && (
          <div className="flex flex-col mt-6">
            <h3>欲匯入的設定:</h3>
            <div className="flex flex-row">
              <Checkbox
                checked={checkMedicine}
                onChange={() => setCheckMedicine((prev) => !prev)}>
                藥物設定
              </Checkbox>
              <Checkbox
                checked={checkICD}
                onChange={() => setCheckICD((prev) => !prev)}>
                診斷碼設定
              </Checkbox>
              <Checkbox
                checked={checkTreatment}
                onChange={() => setCheckTreatment((prev) => !prev)}>
                處置及檢驗設定
              </Checkbox>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ImportModal;
