import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Form, Input, Checkbox } from 'antd';
import { updateUserData } from '@/libs/firebase/database';
import { saveMyNote } from '@/features/medicines/store/medicinesSlice';
import {
  savePickedTrees,
  savePickedMedicines,
  saveMedicineOrder,
} from '@/features/myMedicines/store/myMedicinesSlice';

const { TextArea } = Input;

const extractData = ({ data, type }) => {
  switch (type) {
    case 'MEDICINE': {
      const { myNote } = data[0];
      const { pickedTrees, pickedMedicines, medicineOrder } = data[1];
      return { myNote, pickedTrees, pickedMedicines, medicineOrder };
    }
    case 'ICD': {
      const {
        searchControlPanel,
        segments,
        mostUsed,
        mostUsedOrder,
        pickedICD,
        myICDNote,
      } = data;
      return {
        segmentShortcuts: searchControlPanel.segmentShortcuts,
        segments,
        mostUsed,
        mostUsedOrder,
        pickedICD,
        myICDNote,
      };
    }
    case 'TREATMENT': {
      const {
        searchControlPanel,
        segments,
        mostUsed,
        mostUsedOrder,
        pickedTreatment,
        myTreatmentNote,
      } = data;
      return {
        segmentShortcuts: searchControlPanel.segmentShortcuts,
        segments,
        mostUsed,
        mostUsedOrder,
        pickedTreatment,
        myTreatmentNote,
      };
    }

    default:
      break;
  }
};

const ExportModal = ({ isVisible, setVisible }) => {
  const [checkMedicine, setCheckMedicine] = useState(true);
  const [checkICD, setCheckICD] = useState(true);
  const [checkTreatment, setCheckTreatment] = useState(true);
  const medicineData = useSelector((state) => state.medicines);
  const myMedicineData = useSelector((state) => state.myMedicines);
  const ICDData = useSelector((state) => state.ICDSearch);
  const treatmentData = useSelector((state) => state.treatmentSearch);
  const isDownloadable = !checkMedicine && !checkICD && !checkTreatment;

  const handleCancel = () => {
    setVisible(false);
  };

  const handleDownload = () => {
    const element = document.createElement('a');
    const file = new Blob(
      [
        JSON.stringify({
          medicineData:
            checkMedicine &&
            extractData({
              data: [medicineData, myMedicineData],
              type: 'MEDICINE',
            }),
          ICDData: checkICD && extractData({ data: ICDData, type: 'ICD' }),
          treatmentData:
            checkTreatment &&
            extractData({
              data: treatmentData,
              type: 'TREATMENT',
            }),
        }),
      ],
      {
        type: 'text/plain',
      },
    );
    element.href = URL.createObjectURL(file);
    element.download = 'mySetting.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <Modal
      open={isVisible}
      title="匯出個人資料"
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          關閉
        </Button>,
        <Button
          key="link"
          type="primary"
          onClick={handleDownload}
          disabled={isDownloadable}>
          下載
        </Button>,
      ]}>
      <div className="flex flex-col">
        <Checkbox
          checked={checkMedicine}
          onChange={() => setCheckMedicine((prev) => !prev)}>
          藥物設定
        </Checkbox>
        <br />
        <Checkbox
          checked={checkICD}
          onChange={() => setCheckICD((prev) => !prev)}>
          診斷碼設定
        </Checkbox>
        <br />
        <Checkbox
          checked={checkTreatment}
          onChange={() => setCheckTreatment((prev) => !prev)}>
          處置及檢驗設定
        </Checkbox>
      </div>
    </Modal>
  );
};

export default ExportModal;
