import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Tooltip } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { updateSections } from '../store/treatmentSearchSlice';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  autoPlacement,
} from '@floating-ui/react';

const NavPopover = ({
  title,
  popoverData,
  type,
  dataType,
  sections,
  handleClick,
}) => {
  // const { x, y, strategy, refs, context } = useFloating({
  //   placement: type === 'primary' ? 'bottom-start' : 'right-start',
  //   middleware: [
  //     offset(5),
  //     // flip({ fallbackAxisSideDirection: 'end' }),
  //     shift({}),
  //     // autoPlacement({
  //     //   // crossAxis: true,
  //     //   // top-start, right-start, bottom-start, left-start
  //     //   alignment: 'right',
  //     //   autoAlignment: false,
  //     // }),
  //   ],
  //   whileElementsMounted: autoUpdate,
  // });

  if (popoverData.length === 0) {
    const transformedTitle = title.replace('#', '/');
    return (
      <button
        className={clsx(
          'flex justify-start w-full text-lg font-bold px-2 py-1',
        )}
        onClick={() => handleClick({ sections: sections })}>
        <Tooltip placement="left" title={transformedTitle}>
          <span className="truncate ">{transformedTitle}</span>
        </Tooltip>
      </button>
    );
  }

  let buttonType;

  if (type === 'primary') {
    buttonType = <span className="truncate ">{title}</span>;
  } else if (type === 'secondary') {
    buttonType = (
      <Tooltip placement="left" title={title}>
        <span className="truncate ">{title}</span>
      </Tooltip>
    );
  }

  return (
    <Popover className="relative w-full">
      {({ open }) => (
        <>
          <Popover.Button
            // ref={refs.setReference}
            className={clsx(
              'flex justify-between items-center w-full text-lg font-bold px-2 py-1',
              type === 'primary' && 'border-2 border-black',
            )}
            onClick={() => handleClick({ sections })}>
            {buttonType}
            {type !== 'primary' && <CaretRightOutlined />}
          </Popover.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            <Popover.Panel
              className={clsx(
                'absolute z-10',
                type === 'primary' && 'top-1',
                type === 'secondary' && '-top-10 left-40',
              )}
              // ref={refs.setFloating}
              // style={{
              //   position: strategy,
              //   top: y ?? 0,
              //   left: x ?? 0,
              //   width: 'max-content',
              // }}
            >
              <div className="flex flex-col items-start w-40 bg-lime-400 border-4 border-green-600">
                {popoverData?.map((subSegment) => {
                  const { title, data, type } = subSegment;

                  return (
                    <NavPopover
                      key={title}
                      type="secondary"
                      title={title}
                      popoverData={data}
                      dataType={type}
                      handleClick={handleClick}
                      sections={[...sections, title]}
                    />
                  );
                })}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

// const NavItem = ({
//   type,
//   title,
//   popoverData,
//   dataType,
//   sections,
//   handleClick,
// }) => {
//   return (
//     <NavPopover
//       title={title}
//       popoverData={popoverData}
//       type={type}
//       dataType={dataType}
//       sections={[...sections, title]}
//       handleClick={handleClick}
//     />
//   );
// };

export const TreatmentNavBar = ({ navData, handleClick }) => {
  const dispatch = useDispatch();

  const handleUpdateSections = useCallback(
    ({ sections }) => {
      handleClick('SECTION');
      dispatch(updateSections({ sections }));
    },
    [updateSections, handleClick],
  );

  return (
    <div className="flex gap-2">
      {navData.map((segment) => {
        const { title, data, type } = segment;

        return (
          <NavPopover
            key={title}
            type="primary"
            title={title}
            popoverData={data}
            dataType={type}
            sections={[title]}
            handleClick={handleUpdateSections}
          />
        );
      })}
    </div>
  );
};
