const expandKeywords = (keywords, relatedWords) => {
  const keywordWithoutSymbol = keywords
    .filter((keyword) => keyword.search(/[\.\[\]\$\/#]/) >= 0)
    .map((keyword) => keyword.replace(/[\.\[\]\$\/#]/g, ''));

  const enhancedKeywords = [...keywords, ...keywordWithoutSymbol];

  const expandedKeywords = enhancedKeywords.reduce((acc, cur) => {
    if (relatedWords[cur]) {
      return [...acc, ...relatedWords[cur]];
    }
    return acc;
  }, keywords);

  return Array.from(new Set(expandedKeywords));
};

export default expandKeywords;
