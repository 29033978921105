import React, { useRef, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { TreatmentTableControlPanel } from './TreatmentTableControlPanel';
import clsx from 'clsx';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
} from '@floating-ui/react';

export const SegmentPopover = ({
  icon,
  index,
  segmentData,
  addCustomizedTreatment,
  removeCustomizedTreatment,
  mostUsed,
  disabled,
}) => {
  const [zIndex, setZIndex] = useState(10);

  const { x, y, strategy, refs, context } = useFloating({
    placement: 'left',
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: 'end' }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  // const switchZIndex = () => {
  //   setTimeout(() => {
  //     setZIndex((prev) => {
  //       if (prev === 10) {
  //         return 20;
  //       } else {
  //         return 10;
  //       }
  //     });
  //   }, 25);
  // };

  return (
    <Popover
      className={clsx(
        'relative z-10',
        // zIndex == 10 && 'z-10',
        // zIndex == 20 && 'z-20',
      )}>
      <Popover.Button
        className={''}
        // onClick={switchZIndex}
        disabled={disabled}
        ref={refs.setReference}>
        {icon}
      </Popover.Button>
      <Transition
        // show={isNotesSelectOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className="z-20">
        <Popover.Panel
          className={clsx('absolute bg-white z-20')}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: 'max-content',
          }}>
          <TreatmentTableControlPanel
            index={index}
            mostUsed={mostUsed}
            segmentData={segmentData}
            addCustomizedTreatment={addCustomizedTreatment}
            removeCustomizedTreatment={removeCustomizedTreatment}
          />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
