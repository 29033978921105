import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const combineOrder = (medicineData, orderData) => {
  const combinedOrder = medicineData.map((medicine, idx) => {
    const updatedMedicine = { ...medicine };
    const assignedIndex = orderData?.[medicine?.key] ?? idx;

    updatedMedicine.index = assignedIndex;

    return updatedMedicine;
  });

  return combinedOrder.sort((a, b) => a.index - b.index);
};

const combineNote = (rawData, note) => {
  if (!rawData) return [];
  if (!note) return rawData;

  const combinedNote = rawData.map((medicine) => ({
    ...medicine,
    ...note?.[medicine.key],
  }));

  return combinedNote;
};

export const useMyMedicineData = ({ tableData }) => {
  const [combinedMmedicineData, setCombinedMedicineData] = useState([]);

  const { section, data } = tableData;
  const pickedMedicineKey = useSelector(
    (state) => state.myMedicines.pickedMedicines,
  );
  const medicineOrder = useSelector(
    (state) => state.myMedicines.medicineOrder[section],
  );
  const myNote = useSelector((state) => state.medicines.myNote);

  useEffect(() => {
    const pickedMedicine = data.filter((medicine) =>
      pickedMedicineKey.includes(medicine.key),
    );
    const orderedData = combineOrder(pickedMedicine, medicineOrder);
    const combinedNoteData = combineNote(orderedData, myNote);

    setCombinedMedicineData(combinedNoteData);
  }, [tableData]);

  return [combinedMmedicineData, setCombinedMedicineData];
};
