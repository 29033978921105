import React, { useState, useMemo } from 'react';
import { Tooltip } from 'antd';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { Menu } from '@headlessui/react';
import clsx from 'clsx';

const BreadcrumbMenu = ({
  title,
  subSectionData,
  structure,
  updateRoute,
  route,
}) => {
  const isCurrentLast = title === route[route.length - 1];

  return (
    <Menu as="div" className={clsx('relative flex items-center')}>
      {({ open }) => (
        <>
          <Menu.Button
            className={clsx(
              'flex items-center gap-1',
              isCurrentLast ? 'max-w-max' : 'max-w-[24rem]',
            )}>
            <Tooltip placement="top" title={title}>
              <span className="truncate">{title}</span>
            </Tooltip>
            <ArrowLongRightIcon
              className={clsx(
                'shrink-0 h-6 w-6 transition duration-100',
                open && 'rotate-90',
              )}
            />
          </Menu.Button>
          <Menu.Items className="absolute top-9 -left-2 flex flex-col bg-teal-400">
            {subSectionData.map((subSection) => {
              const { title } = subSection;
              return (
                <Menu.Item key={title}>
                  {({ active }) => (
                    <div
                      className={`min-w-[160px] max-w-[48rem] px-2 py-1 ${
                        active && 'bg-teal-200'
                      }`}>
                      <Tooltip placement="right" title={title}>
                        <button
                          className="flex justify-start items-start w-full"
                          onClick={() =>
                            updateRoute({ sections: [...structure, title] })
                          }>
                          <span className="truncate">{title}</span>
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

const findCurrentSection = (sectionData, structure) => {
  let currentSection;
  let subSectionData = sectionData;

  for (let i = 0; i < structure.length; i++) {
    currentSection = structure[i];

    subSectionData = subSectionData?.find(
      (subSection) => subSection.title === currentSection,
    )?.data;
    // check if currentSection reach section end
  }

  return subSectionData;
};

const Breadcrumb = ({ title, sectionData, structure, updateRoute, route }) => {
  const subSectionData = useMemo(
    () => findCurrentSection(sectionData, structure),
    [sectionData, structure],
  );
  const hasSubSection = subSectionData.length > 0;
  let content;

  if (hasSubSection) {
    content = (
      <div className="flex row items-center px-2 py-1 text-xl font-bold">
        {/* <div>{title}</div> */}
        {hasSubSection && (
          <BreadcrumbMenu
            title={title}
            subSectionData={subSectionData}
            structure={structure}
            updateRoute={updateRoute}
            route={route}
          />
        )}
      </div>
    );
  } else {
    content = (
      <div className="flex row items-center max-w-max px-2 py-1 text-xl font-bold">
        <Tooltip placement="top" title={title}>
          <span className="truncate">{title}</span>
        </Tooltip>
      </div>
    );
  }

  return content;
};

export const Breadcrumbs = ({ breadcrumbData, route, updateRoute }) => {
  if (breadcrumbData.length === 0) {
    return <div className="flex w-full mt-2 h-9 bg-teal-400 z-10"></div>;
  }

  return (
    <div className="flex w-full mt-2 h-9 bg-teal-400 z-10">
      {breadcrumbData &&
        route.map((section, idx) => {
          const structure = route.slice(0, idx + 1);

          return (
            <Breadcrumb
              key={section}
              title={section}
              sectionData={breadcrumbData}
              structure={structure}
              route={route}
              updateRoute={updateRoute}
            />
          );
        })}
    </div>
  );
};
