import React, { useState, useEffect } from 'react';
// import {
//   sortableContainer,
//   sortableElement,
//   sortableHandle,
// } from 'react-sortable-hoc';
import { Tooltip, notification } from 'antd';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  PlusIcon,
  XMarkIcon,
  Bars3Icon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import { arrayMoveImmutable } from 'array-move';
import { Menu, Transition, Popover } from '@headlessui/react';
import clsx from 'clsx';
import { Test } from '@/features/treatmentSearch/components/Test';
import { TreatmentSearch } from './../../treatmentSearch/routes/TreatmentSearch';

// const DragHandle = sortableHandle(() => (
//   <Bars3Icon className="h-6 w-6 cursor-pointer" />
// ));

const SortableItem = ({
  title,
  handleSelect,
  removeSegment,
  close,
  type,
  isEditing,
  setMode,
  parentSegment,
  handleDisplayCustomized,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: title });
  const style = {
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };

  const handleClose = () => {
    handleSelect(title);

    if (type === 'child') {
      setMode('CUSTOMIZED');
      handleDisplayCustomized({ parent: parentSegment, child: title });
    }

    close();
  };

  const handleRemove = () => {
    removeSegment({ segment: title, type });
  };

  return (
    <li
      className="flex items-end gap-1"
      ref={setNodeRef}
      style={style}
      {...attributes}>
      {/* <div className="w-full flex justify-between items-end cursor-pointer"> */}

      <Tooltip
        placement="left"
        title={title}
        overlayClassName={'mr-4'}
        overlayStyle={{ marginRight: 20 }}>
        <div className="w-full flex items-end cursor-pointer">
          {isEditing && (
            <Bars3Icon
              className="h-6 w-6 cursor-pointer"
              ref={setActivatorNodeRef}
              {...listeners}
            />
          )}
          <div
            className={clsx(
              'flex items-center w-full font-bold text-xl',
              isEditing && 'w-24',
            )}
            onClick={handleClose}>
            <span className="overflow-hidden text-ellipsis">{title}</span>
          </div>
        </div>
      </Tooltip>
      {isEditing && (
        <XMarkIcon className="h-6 w-6 cursor-pointer" onClick={handleRemove} />
      )}
    </li>
  );
};

// const SortableContainer = sortableContainer(({ children }) => {
//   return <ul>{children}</ul>;
// });

export function HeaderSegmentDropdown({
  type,
  isEditing,
  children,
  setSegment,
  segmentData,
  addSegment,
  removeSegment,
  updateSegments,
  setMode,
  parentSegment,
  handleDisplayCustomized,
}) {
  const [customOpen, setCustomOpen] = useState(false);
  const [input, setInput] = useState('');
  // const [items, setItems] = useState();
  const disabled = type === 'child' && parentSegment === '';

  // useEffect(() => setItems(segmentData), [segmentData]);

  // const handleClick = () => {
  //   if (type == 'child' && !parentSegment) return;

  //   setCustomOpen((prev) => !prev);
  // };

  const handleAddSegment = () => {
    if (!input) return;
    const isDuplicate =
      segmentData?.findIndex((item) => item.title === input) >= 0;
    if (isDuplicate) {
      return notification.warning({
        message: '名稱重複',
        description: `${input} 已存在，請改用其他名稱`,
        duration: 2,
      });
    }
    addSegment(input);
    setInput('');
  };

  const handleInputEnter = (e) => {
    if (e.key === 'Enter') {
      handleAddSegment();
    }
  };

  const handleSelect = (value) => {
    setSegment(value);
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = segmentData.findIndex(
        (item) => item.title === active.id,
      );
      const newIndex = segmentData.findIndex((item) => item.title === over.id);
      const updatedData = arrayMove(segmentData, oldIndex, newIndex);
      updateSegments({ type, data: updatedData });
    }
  }

  return (
    <Popover as="div" className="relative z-10">
      {({ open }) => (
        <>
          <Popover.Button
            as="div"
            onClick={() => setInput('')}
            disabled={disabled}
            className={clsx(
              'w-40 h-full font-bold text-xl flex justify-between items-center p-2',
              type == 'parent' && 'bg-blue-400',
              type == 'child' && 'bg-teal-400',
              // disabled && 'cursor-not-allowed',
            )}>
            <Tooltip placement="bottom" title={children}>
              <div className="w-28">
                <div className="truncate">{children}</div>
              </div>
            </Tooltip>

            {!disabled && (
              <ChevronDownIcon
                className={clsx(
                  'h-6 w-6 ease-in duration-200',
                  open && 'rotate-180',
                )}
              />
            )}
          </Popover.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-100 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            {open && (
              <Popover.Panel
                className={clsx(
                  'w-40 absolute top-[4px] right-0 flex flex-col p-2 gap-2 border',
                  type == 'parent' && 'border-blue-500 bg-blue-400',
                  type == 'child' && 'border-teal-500 bg-teal-400',
                )}>
                {({ close }) => (
                  <>
                    {isEditing && (
                      <div className="flex justify-between">
                        <input
                          type="text"
                          className="w-4/5 px-2"
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                          onKeyDown={handleInputEnter}
                        />
                        <PlusIcon
                          className="h-6 w-6"
                          onClick={handleAddSegment}
                        />
                      </div>
                    )}

                    {segmentData?.length > 0 && (
                      <>
                        {isEditing && (
                          <div className="w-full border-b border-black"></div>
                        )}
                        <DndContext
                          onDragEnd={handleDragEnd}
                          collisionDetection={closestCenter}>
                          <SortableContext
                            items={segmentData?.map((item) => item.title)}
                            strategy={verticalListSortingStrategy}>
                            {segmentData?.map((item, index) => (
                              <SortableItem
                                key={item.title}
                                type={type}
                                isEditing={isEditing}
                                index={index}
                                title={item.title}
                                handleSelect={handleSelect}
                                removeSegment={removeSegment}
                                close={close}
                                setMode={setMode}
                                // only type child have
                                parentSegment={parentSegment}
                                handleDisplayCustomized={
                                  handleDisplayCustomized
                                }
                              />
                            ))}
                          </SortableContext>
                        </DndContext>
                      </>
                    )}
                  </>
                )}
              </Popover.Panel>
            )}
          </Transition>
        </>
      )}
    </Popover>
  );
}
