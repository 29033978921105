import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateUserData } from '@/libs/firebase/database';

export const addSearchKeyword = createAsyncThunk(
  'medicineSearch/addSearchKeyword',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;
    let newKeywords = state.medicineSearch.searchHistory.filter(
      (keyword) => keyword !== payload,
    );
    newKeywords.unshift(payload);

    if (newKeywords.length > 8) {
      newKeywords = newKeywords.slice(0, 8);
    }

    await updateUserData(uid, 'medicineSearchHistory', newKeywords);

    return newKeywords;
  },
);

export const removeSearchKeyword = createAsyncThunk(
  'medicineSearch/removeSearchKeyword',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;

    let newSearchHistory = state.medicineSearch.searchHistory.filter(
      (keyword) => keyword !== payload,
    );

    await updateUserData(uid, 'medicineSearchHistory', newSearchHistory);

    return newSearchHistory;
  },
);

export const updateSearchKeyword = createAsyncThunk(
  'medicineSearch/updateSearchKeyword',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;

    let newSearchHistory = payload;

    await updateUserData(uid, 'medicineSearchHistory', newSearchHistory);

    return newSearchHistory;
  },
);

export const medicineSearchSlice = createSlice({
  name: 'medicineSearch',
  initialState: {
    searchHistory: [],
    isSaving: {
      searchHistory: false,
    },
  },
  reducers: {
    loadMedicineSearchHistory: (state, action) => {
      state.searchHistory = action.payload?.medicineSearchHistory || [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addSearchKeyword.pending, (state, action) => {
      state.isSaving.searchHistory = true;
    });
    builder.addCase(addSearchKeyword.fulfilled, (state, action) => {
      state.searchHistory = action.payload;
      state.isSaving.searchHistory = false;
    });
    builder.addCase(removeSearchKeyword.pending, (state, action) => {
      state.isSaving.searchHistory = true;
    });
    builder.addCase(removeSearchKeyword.fulfilled, (state, action) => {
      state.searchHistory = action.payload;
      state.isSaving.searchHistory = false;
    });
    builder.addCase(updateSearchKeyword.pending, (state, action) => {
      state.isSaving.searchHistory = true;
    });
    builder.addCase(updateSearchKeyword.fulfilled, (state, action) => {
      state.searchHistory = action.payload;
      state.isSaving.searchHistory = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { loadMedicineSearchHistory } = medicineSearchSlice.actions;

export default medicineSearchSlice.reducer;
