import * as React from 'react';
import { ForbiddenFallback } from './ForbiddenFallback';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useEffect } from 'react';

export const ROLES = {
  ADMIN: 'ADMIN',
  PREMIUM: 'PREMIUM',
  TRIAL: 'TRIAL',
};

export const POLICIES = {
  // 'comment:delete': (user, comment) => {
  //   if (user.role === 'ADMIN') {
  //     return true;
  //   }

  //   if (user.role === 'USER' && comment.authorId === user.id) {
  //     return true;
  //   }

  //   return false;
  // },
  controlPanel: (userEmail) => {
    if (userEmail) {
      return true;
    }

    return false;
  },
};

// TODO: change checkAccess method from user.email to user.role

export const useAuthorization = () => {
  const { uid, role, expiration } = useSelector((state) => state.control.auth);

  if (!uid) {
    throw Error('User does not exist!');
  }

  const checkAccess = React.useCallback(
    ({ allowedRoles }) => {
      if (allowedRoles && allowedRoles.length > 0) {
        const isActive = moment().valueOf() < moment(expiration).valueOf();

        return allowedRoles?.includes(role) && isActive;
      }

      return true;
    },
    [role],
  );

  return { checkAccess, role };
};

// type AuthorizationProps = {
//   forbiddenFallback?: React.ReactNode;
//   children: React.ReactNode;
// } & (
//   | {
//       allowedRoles: RoleTypes[];
//       policyCheck?: never;
//     }
//   | {
//       allowedRoles?: never;
//       policyCheck: boolean;
//     }
// );

export const Authorization = ({
  policyCheck,
  allowedRoles,
  forbiddenFallback = <ForbiddenFallback />,
  children,
}) => {
  const { checkAccess } = useAuthorization();
  const navigate = useNavigate();
  const { emailVerified } = useSelector((state) => state.control.auth);

  let canAccess = false;

  useEffect(() => {
    if (emailVerified == false && process.env.NODE_ENV !== 'development') {
      navigate('/verify');
    }
  }, [emailVerified]);

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== 'undefined') {
    canAccess = policyCheck;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
