import React, { useState } from 'react';
import { Divider, Tooltip, notification } from 'antd';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { PanelSegmentPopoverButton as PopoverButton } from '@/features/searchControlPanel';

const segmentToTreatment = (segmentData, targetTreatment) => {
  const relatedSegments = segmentData.reduce((acc, curP) => {
    const childSegmentResult = curP?.childSegments?.reduce((acc, curC) => {
      if (curC?.relatedTreatment?.data?.includes(targetTreatment)) {
        return [...acc, curC.title];
      } else {
        return acc;
      }
    }, []);

    if (childSegmentResult) {
      const segmentPair = childSegmentResult.map((cSegment) => [
        curP.title,
        cSegment,
      ]);

      return [...acc, ...segmentPair];
    } else {
      return acc;
    }
  }, []);
  return relatedSegments;
};

function SegmentButton({ type }) {
  return (
    <button
      className={clsx(
        'col-span-2 border-2 border-black',
        type == 'parent' && 'bg-blue-400',
        type == 'child' && 'bg-teal-400',
      )}>
      {type == 'parent' ? '父類別' : '子類別'}
    </button>
  );
}

function UtilityButton({ type, onClick, activated }) {
  return (
    <button
      className={clsx(
        'col-span-1 border-2 border-black text-black',
        activated && 'bg-green-400',
      )}
      onClick={() => onClick({ type: activated ? 'REMOVE' : 'ADD' })}>
      {type == 'MOST_USED' ? '常用' : '新增'}
    </button>
  );
}

function SegmentList({ segment, handleRemove }) {
  return (
    <div className="w-full flex items-center gap-2">
      <div className="w-38">
        <div className="flex flex-col items-start border border-slate-500 px-2 w-full">
          <Tooltip
            placement="left"
            title={`${segment[0]}\n${segment[1]}`}
            overlayInnerStyle={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            }}>
            <div className="flex w-32">
              <span className="font-bold text-blue-400 text-xl truncate">
                {segment[0]}
              </span>
            </div>
            <div className="flex w-32">
              <span className="font-bold text-teal-400 text-xl truncate">
                {segment[1]}
              </span>
            </div>
          </Tooltip>
        </div>
      </div>
      <button
        className="w-full p-1"
        onClick={() => handleRemove({ parent: segment[0], child: segment[1] })}>
        <NoSymbolIcon className="text-red-500" />
      </button>
    </div>
  );
}

export const TreatmentTableControlPanel = React.memo(
  function TreatmentTableControlPanel({
    index,
    segmentData,
    mostUsed,
    addCustomizedTreatment,
    removeCustomizedTreatment,
  }) {
    const [parent, setParent] = useState('');
    const [child, setChild] = useState('');
    const parentSegmentData = segmentData;
    const childSegmentData = parentSegmentData?.find(
      (segment) => segment.title === parent,
    )?.childSegments;
    const relatedSegments = segmentToTreatment(segmentData, index);

    const handleAddIntoMost = () => {
      addCustomizedTreatment({ type: 'MOST_USED', code: index });
    };

    const handleRemoveFromMost = () => {
      removeCustomizedTreatment({ type: 'MOST_USED', code: index });
    };

    const handleMost = ({ type }) => {
      if (type === 'ADD') {
        handleAddIntoMost();
      }
      if (type === 'REMOVE') {
        handleRemoveFromMost();
      }
    };

    const handleAddIntoSegment = () => {
      const targetSegment = segmentData
        ?.find((pSegment) => pSegment.title === parent)
        ?.childSegments.find((cSegment) => cSegment.title === child);
      const relatedTreatment = targetSegment.relatedTreatment;
      const hasTreatment = relatedTreatment.data.some(
        (treatment) => treatment === index,
      );
      if (hasTreatment) {
        return notification.warning({
          message: '資料重複',
          description: `${parent}/${child} 中已存在該筆資料`,
          duration: 2,
        });
      }
      addCustomizedTreatment({
        type: 'CUSTOMIZED',
        parent,
        child,
        code: index,
      });
      setParent('');
      setChild('');
    };

    const handleRemoveFromSegment = ({ parent, child }) => {
      removeCustomizedTreatment({
        type: 'CUSTOMIZED/panel',
        parent,
        child,
        code: index,
      });
    };

    const activated = mostUsed.includes(index);

    return (
      <div className="flex flex-col items-center w-60 border-2 border-black">
        <div className="grid grid-template grid-cols-3 grid-rows-2 gap-2 w-full p-2 ">
          <PopoverButton
            className="col-span-2"
            type="parent"
            segmentData={parentSegmentData}
            segment={parent}
            setSegment={setParent}
            resetChild={setChild}
            open={open}
            direction="left"
          />
          <UtilityButton
            type="MOST_USED"
            onClick={handleMost}
            activated={activated}>
            常用
          </UtilityButton>
          <PopoverButton
            className="col-span-2"
            type="child"
            segmentData={childSegmentData}
            segment={child}
            disabled={!parent}
            setSegment={setChild}
            open={open}
            direction="left"
          />
          <UtilityButton type="CUSTOMIZED" onClick={handleAddIntoSegment}>
            新增
          </UtilityButton>
        </div>
        <div className="w-11/12 border-b border-slate-300" />
        {relatedSegments.length > 0 && (
          <div className="w-full p-2 flex flex-col gap-1 max-h-52 overflow-y-scroll">
            {relatedSegments.map((segment) => (
              <SegmentList
                key={segment[1]}
                segment={segment}
                handleRemove={handleRemoveFromSegment}
              />
            ))}
          </div>
        )}
      </div>
    );
  },
);
