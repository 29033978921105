import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const combineOrder = (rawData, orderData) => {
  if (!rawData) return [];

  const combinedOrder = rawData.map((treatment, idx) => {
    const updatedTreatment = { ...treatment };
    const assignedIndex = orderData?.[treatment.code] ?? idx;

    updatedTreatment.index = assignedIndex;

    return updatedTreatment;
  });

  return combinedOrder.sort((a, b) => a.index - b.index);
};

export const useCustomizedTreatmentData = ({
  treatmentData: baseData,
  mode,
  currentSegment,
}) => {
  const [customizedTreatmentData, setCustomizedTreatmentData] = useState([]);
  const { segments, mostUsedOrder } = useSelector(
    (state) => state.treatmentSearch,
  );

  useEffect(() => {
    let orderedData;

    if (mode === 'MOST_USED') {
      orderedData = combineOrder(baseData, mostUsedOrder);
    } else {
      const { parentSegment, childSegment } = currentSegment;
      const treatmentOrder = segments
        .find((pSegment) => pSegment.title === parentSegment)
        .childSegments.find((cSegment) => cSegment.title === childSegment)
        .relatedTreatment?.order;

      orderedData = combineOrder(baseData, treatmentOrder);
    }

    setCustomizedTreatmentData(orderedData);
  }, [baseData]);

  return [customizedTreatmentData, setCustomizedTreatmentData];
};
