import React from 'react';
import { Tabs, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './MedicineShowcase.css';

export const MedicineShowcase = React.memo(
  React.forwardRef(
    ({ tabs, sectionData, paneData, store, children, showcaseRef }, ref) => {
      const dispatch = useDispatch();
      const isEditingTable = useSelector(
        (state) => state.control.control.isEditingTable,
      );

      const UPDATE_PANES = `${store}/updatePanes`;

      const onChange = (newActiveKey) => {
        // setActiveKey(newActiveKey);
        if (isEditingTable.status) {
          notification.warning({
            message: '無法切換分頁',
            description: `<${isEditingTable.editingRowName}> 尚未儲存，請確認儲存後再次操作`,
            duration: 5,
          });
          return;
        }
        dispatch({
          type: UPDATE_PANES,
          payload: { activeKey: newActiveKey, data: paneData.data },
        });
      };

      const remove = (targetKey) => {
        let newActiveKey = paneData.activeKey;
        let lastIndex = -1;

        paneData.data.forEach((pane, i) => {
          if (pane.key === targetKey) {
            lastIndex = i - 1;
          }
        });

        const newPanesData = paneData.data.filter(
          (pane, i) => pane.key !== targetKey,
        );

        if (newPanesData.length && targetKey === paneData.activeKey) {
          if (lastIndex >= 0) {
            newActiveKey = newPanesData[lastIndex].key;
          } else {
            newActiveKey = newPanesData[0].key;
          }
        }
        dispatch({
          type: UPDATE_PANES,
          payload: { activeKey: newActiveKey, data: newPanesData },
        });
      };

      const onEdit = (targetKey, action) => {
        remove(targetKey);
      };

      return (
        <div ref={showcaseRef} className="mt-14">
          <Tabs
            items={tabs}
            type="editable-card"
            onChange={onChange}
            onEdit={onEdit}
            activeKey={paneData.activeKey}
            hideAdd>
            {children}
          </Tabs>
        </div>
      );
    },
  ),
);

MedicineShowcase.displayName = 'MedicineShowcase';
