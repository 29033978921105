import React from 'react';
import { PanelSegmentPopover } from './PanelSegmentPopover';
import clsx from 'clsx';

export const PanelShortcuts = ({
  shortcutData,
  currentSegment,
  segmentData,
  updateSegmentShortcut,
  handleDisplayCustomized,
  col,
  isEditing,
}) => {
  return (
    <div
      className={clsx(
        `grid gap-2 mt-4`,
        col === 4 && 'grid-cols-4',
        col === 5 && 'grid-cols-5',
      )}>
      {shortcutData?.map((shortcut, idx) => (
        <PanelSegmentPopover
          shortcutData={shortcut}
          index={idx}
          currentSegment={currentSegment}
          segmentData={segmentData}
          updateSegmentShortcut={updateSegmentShortcut}
          key={idx}
          handleDisplayCustomized={handleDisplayCustomized}
          isEditing={isEditing}
        />
      ))}
    </div>
  );
};
