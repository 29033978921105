import { Table } from 'antd';
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  forwardRef,
} from 'react';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { MenuOutlined } from '@ant-design/icons';
import { CaretRightOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSelector, useDispatch } from 'react-redux';
import { arrayMoveImmutable } from 'array-move';
import { clsx } from 'clsx';
import { SegmentPopover } from './SegmentPopover';
import { togglePaginationMode } from '@/features/controlPanel/store/controlSlice';
import {
  addCustomizedTreatment,
  removeCustomizedTreatment,
  updateTreatmentOrder,
} from '../store/treatmentSearchSlice';
import { useCustomizedTreatmentData } from '../hooks/useCustomizedTreatmentData';
import { downloadNoteCode } from '@/libs/firebase/storage';

const PAGE_SIZE = 10;

const DragHandle = forwardRef(({ isEditing, listeners }, ref) =>
  isEditing ? (
    <MenuOutlined
      style={{
        cursor: 'move',
        color: '#0a2844',
        touchAction: 'none',
      }}
      ref={ref}
      {...listeners}
    />
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className="w-2 h-2">
      {/* <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
      <path
        fill="#7c9ab6"
        d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256 352C309 352 352 309 352 256C352 202.1 309 160 256 160C202.1 160 160 202.1 160 256C160 309 202.1 352 256 352z"
      />
    </svg>
  ),
);
DragHandle.displayName = 'DragHandle';

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: child.props.render({
              ref: setActivatorNodeRef,
              listeners: listeners,
            }),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const CustomizedTreatmentTable = ({
  treatmentData,
  mode,
  currentSegment,
}) => {
  const dispatch = useDispatch();
  const onePageMode = useSelector(
    (state) => state.control.userPreference.onePageMode,
  );
  const isEditing = useSelector((state) => state.control.control.isEditing);
  const { mostUsed, segments } = useSelector((state) => state.treatmentSearch);
  const { selectedTreatmentHospitalCol } = useSelector(
    (state) => state.control.userPreference,
  );
  const { parentSegment, childSegment } = currentSegment;
  // const treatmentOrder = useMemo(() => {
  //   if (!parentSegment || !childSegment) return [];

  //   return segments
  //     .find((pSegment) => pSegment.title === parentSegment)
  //     .childSegments.find((cSegment) => cSegment.title === childSegment)
  //     .relatedTreatment.order;
  // }, [segments, parentSegment, childSegment]);

  // const treatmentDataWithNoteAndSorting = useMemo(() => {
  //   let orderedData;

  //   if (mode === 'MOST_USED') {
  //     orderedData = combineOrder(baseData, mostUsedOrder);
  //   } else {
  //     orderedData = combineOrder(baseData, treatmentOrder);
  //   }
  //   const combinedNoteData = combineNote(orderedData, myTreatmentNote);

  //   return combinedNoteData;
  // }, [baseData, mostUsedOrder, treatmentOrder]);

  const [customizedTreatmentData, setCustomizedTreatmentData] =
    useCustomizedTreatmentData({ treatmentData, mode, currentSegment });

  const handleAddCustomizedTreatmentData = useCallback(
    (props) => {
      dispatch(addCustomizedTreatment(props));
    },
    [addCustomizedTreatment],
  );

  const handleRemoveCustomizedTreatmentData = useCallback(
    (props) => {
      dispatch(removeCustomizedTreatment(props));
    },
    [removeCustomizedTreatment],
  );

  const handleRemoveTreatment = useCallback(
    ({ type, parent, child, code }) => {
      const [buttonMode, componentType] = type.split('/');
      console.log('remove', type, parent, child, code);
      if (buttonMode === 'MOST_USED') {
        handleRemoveCustomizedTreatmentData({ type: buttonMode, code });
      } else if (buttonMode === 'CUSTOMIZED') {
        handleRemoveCustomizedTreatmentData({
          type: buttonMode,
          parent,
          child,
          code,
        });
      }
      // Update treatmentData(to trigger rerender) if treatment in currentSegment
      if (
        (buttonMode === 'MOST_USED' && mode === 'MOST_USED') ||
        (parent === parentSegment && child === childSegment)
      ) {
        const updatedBaseData = customizedTreatmentData.filter(
          (treatment) => treatment.code !== code,
        );
        setCustomizedTreatmentData(updatedBaseData);
      }
    },
    [handleRemoveCustomizedTreatmentData, customizedTreatmentData],
  );

  const handlePagination = () => {
    dispatch(togglePaginationMode(!onePageMode));
  };

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      key: 'sort',
      width: '5%',
      className: 'drag-visible',
      render: ({ ref, listeners }) => (
        <div className="flex justify-center">
          <DragHandle isEditing={isEditing} ref={ref} listeners={listeners} />
        </div>
      ),
    },
    {
      title: '代碼',
      dataIndex: 'code',
      key: 'code',
      width: 60,
      render: (text) => <a>{text}</a>,
    },
    {
      title: '',
      dataIndex: 'remove',
      key: 'remove',
      width: 60,
      render: (text, record) =>
        isEditing && (
          <XMarkIcon
            className="w-6 h-6 text-red-500"
            onClick={() =>
              handleRemoveTreatment({
                type: mode,
                parent: parentSegment,
                child: childSegment,
                code: record.code,
              })
            }
          />
        ),
    },
    {
      title: '診療項目',
      dataIndex: 'item',
      key: 'item',
      width: 240,
      render: (text, record) => {
        return (
          <div
            className="flex flex-col"
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            }}>
            <span>{record['item - Chinese']}</span>
            <span>
              {selectedTreatmentHospitalCol['item - English'] &&
                record['item - English']}
            </span>
          </div>
        );
      },
    },
    {
      title: '給付規定',
      dataIndex: 'rule',
      key: 'rule',
      width: 360,
      render: (text, record) => (
        <div
          className=""
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}>
          {text}
          {record['note - code'] && (
            <div className="mt-4">
              {record['note - code'].split('$').map((code) => (
                <a
                  key={code}
                  onClick={() => downloadNoteCode({ key: code })}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center">
                  {code}
                </a>
              ))}
            </div>
          )}
        </div>
      ),
    },
    {
      title: (
        <div className="flex justify-center">
          基層
          <br />
          院所
        </div>
      ),
      dataIndex: 'clinic',
      key: 'clinic',
      width: 70,
      render: (text) => <div className="text-center">{text}</div>,
    },
    {
      title: (
        <div className="flex justify-center">
          地區
          <br />
          醫院
        </div>
      ),
      dataIndex: 'local',
      key: 'local',
      width: 70,
      render: (text) => <div className="text-center">{text}</div>,
    },
    {
      title: (
        <div className="flex justify-center">
          區域
          <br />
          醫院
        </div>
      ),
      dataIndex: 'region',
      key: 'region',
      width: 70,
      render: (text) => <div className="text-center">{text}</div>,
    },
    {
      title: (
        <div className="flex justify-center">
          醫學
          <br />
          中心
        </div>
      ),
      dataIndex: 'center',
      key: 'center',
      width: 70,
      render: (text) => <div className="text-center">{text}</div>,
    },
    {
      title: <div className="flex justify-center">其他</div>,
      dataIndex: 'other',
      key: 'other',
      width: 70,
    },
    {
      title: (
        <div className="flex justify-center">
          健保
          <br />
          點數
        </div>
      ),
      dataIndex: 'charge',
      key: 'charge',
      width: 95,
      render: (text) => <div className="text-center">{text}</div>,
    },
    {
      title: <div className="flex justify-center">類別</div>,
      dataIndex: 'segment',
      width: 70,
      key: 'segment',
      render: (text, record) => (
        <div className="flex justify-center">
          <SegmentPopover
            segmentData={segments}
            mostUsed={mostUsed}
            addCustomizedTreatment={handleAddCustomizedTreatmentData}
            removeCustomizedTreatment={handleRemoveTreatment}
            index={record.code}
            disabled={!isEditing}
            icon={
              <CaretRightOutlined
                className="text-teal-400"
                style={{ color: '#2dd4bf', fontSize: '24px' }}
              />
            }
          />
        </div>
      ),
    },
    {
      title: <div className="text-center">備註</div>,
      dataIndex: 'note',
      width: 180,
      key: 'note',
      render: (text, record) => (
        <div
          className="text-center"
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}>
          {text}
        </div>
      ),
    },
  ];

  const filteredColumns = columns.filter((col) => {
    const regularCols = [
      'sort',
      'code',
      'remove',
      'item',
      'segment',
      'note',
      'operation',
    ];
    if (regularCols.includes(col.dataIndex)) {
      return true;
    }
    return selectedTreatmentHospitalCol[col.dataIndex];
  });

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = customizedTreatmentData.findIndex(
        (i) => i.code === active.id,
      );
      const overIndex = customizedTreatmentData.findIndex(
        (i) => i.code === over?.id,
      );
      const updatedTreatmentData = arrayMove(
        customizedTreatmentData,
        activeIndex,
        overIndex,
      );

      setCustomizedTreatmentData(updatedTreatmentData);

      const orderRecord = {};

      updatedTreatmentData.forEach((treatment, idx) => {
        orderRecord[treatment['code']] = idx;
      });

      if (mode === 'MOST_USED') {
        dispatch(updateTreatmentOrder({ mode, content: orderRecord }));
      } else {
        dispatch(
          updateTreatmentOrder({
            mode,
            parent: parentSegment,
            child: childSegment,
            content: orderRecord,
          }),
        );
      }
    }
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={customizedTreatmentData?.map((i) => i.code)}
        strategy={verticalListSortingStrategy}>
        <Table
          id="myTable"
          dataSource={customizedTreatmentData}
          columns={filteredColumns}
          rowKey="code"
          style={{ fontSize: '24px' }}
          components={{
            body: {
              row: Row,
            },
          }}
          pagination={!onePageMode}
        />
        {mode === 'CUSTOMIZED' && (
          <div className={clsx('flex justify-end', onePageMode && 'mt-4')}>
            <button
              onClick={handlePagination}
              className="p-2 border border-slate-300">
              {onePageMode ? '分頁顯示' : '單頁顯示'}
            </button>
          </div>
        )}
      </SortableContext>
    </DndContext>
  );
};
