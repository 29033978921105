import { configureStore } from '@reduxjs/toolkit';
import controlSlice from '@/features/controlPanel/store/controlSlice';
import medicinesSlice from '@/features/medicines/store/medicinesSlice';
import myMedicinesSlice from '@/features/myMedicines/store/myMedicinesSlice';
import medicineSearchSlice from '@/features/medicineSearch/store/medicineSearchSlice';
import ICDSearchSlice from '@/features/ICDSearch/store/ICDSearchSlice';
import treatmentSearchSlice from '@/features/treatmentSearch/store/treatmentSearchSlice';

export default configureStore({
  reducer: {
    control: controlSlice,
    medicines: medicinesSlice,
    myMedicines: myMedicinesSlice,
    medicineSearch: medicineSearchSlice,
    ICDSearch: ICDSearchSlice,
    treatmentSearch: treatmentSearchSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
