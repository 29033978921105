import React, { useEffect, useRef } from 'react';

export const useUnload = (condition) => {
  // const cb = useRef(fn);
  const handleUnload = (e) => {
    if (condition) {
      e.preventDefault();
      e.returnValue = '您尚未儲存，確定要離開嗎?';
      return '您尚未儲存，確定要離開嗎?';
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [handleUnload]);
};
