import {
  createSlice,
  createAsyncThunk,
  current,
  original,
} from '@reduxjs/toolkit';
import { generateKey } from '@/utils/generateKey';
import { updateUserData } from '@/libs/firebase/database';

// export const updatePickedTrees = createAsyncThunk(
//   'myMedicines/updatePickedTrees',
//   async (payload, thunkAPI) => {
//     const state = thunkAPI.getState();
//     const { key, checked } = payload;
//     const uid = state.control.auth.uid;
//     const updatedTrees = {
//       ...state.myMedicines.pickedTrees,
//       [key]: checked,
//     };

//     await updateUserData(uid, 'pickedTrees', updatedTrees);

//     return updatedTrees;
//   },
// );

export const savePickedTrees = createAsyncThunk(
  'myMedicines/savePickedTrees',
  async (payload, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const state = getState();
    const uid = state.control.auth.uid;

    const result = await updateUserData(uid, 'pickedTrees', payload);

    if (result instanceof Error) {
      return rejectWithValue('failed');
    }

    return payload;
  },
);

// export const addPickedMedicine = createAsyncThunk(
//   'myMedicines/addPickedMedicine',
//   async (payload, thunkAPI) => {
//     const state = thunkAPI.getState();
//     const uid = state.control.auth.uid;
//     const updatedPickedMedicines = [
//       ...state.myMedicines.pickedMedicines,
//       payload,
//     ];

//     await updateUserData(uid, 'pickedMedicines', updatedPickedMedicines);

//     return updatedPickedMedicines;
//   },
// );

// export const removePickedMedicine = createAsyncThunk(
//   'myMedicines/removePickedMedicine',
//   async (payload, thunkAPI) => {
//     const state = thunkAPI.getState();
//     const uid = state.control.auth.uid;
//     const updatedPickedMedicines = [
//       ...state.myMedicines.pickedMedicines,
//     ].filter((key) => key !== payload);

//     await updateUserData(uid, 'pickedMedicines', updatedPickedMedicines);

//     return updatedPickedMedicines;
//   },
// );

// export const selectAllPickedMedicines = createAsyncThunk(
//   'myMedicines/selectAllPickedMedicines',
//   async (payload, thunkAPI) => {
//     const state = thunkAPI.getState();
//     const uid = state.control.auth.uid;
//     const set = new Set([...state.myMedicines.pickedMedicines, ...payload]);
//     const updatedPickedMedicines = Array.from(set);

//     await updateUserData(uid, 'pickedMedicines', updatedPickedMedicines);

//     return updatedPickedMedicines;
//   },
// );

// export const removeAllPickedMedicines = createAsyncThunk(
//   'myMedicines/removeAllPickedMedicines',
//   async (payload, thunkAPI) => {
//     const state = thunkAPI.getState();
//     const uid = state.control.auth.uid;
//     const updatedPickedMedicines = [
//       ...state.myMedicines.pickedMedicines,
//     ].filter((key) => !payload.includes(key));

//     await updateUserData(uid, 'pickedMedicines', updatedPickedMedicines);

//     return updatedPickedMedicines;
//   },
// );

export const savePickedMedicines = createAsyncThunk(
  'myMedicines/savePickedMedicines',
  async (payload, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const state = getState();
    const uid = state.control.auth.uid;

    const result = await updateUserData(uid, 'pickedMedicines', payload);

    if (result instanceof Error) {
      return rejectWithValue('failed');
    }

    return payload;
  },
);

// export const updateMedicineOrder = createAsyncThunk(
//   'myMedicines/updateMedicineOrder',
//   async (payload, thunkAPI) => {
//     const state = thunkAPI.getState();
//     const uid = state.control.auth.uid;
//     const updatedMedicineOrder = {
//       ...state.myMedicines.medicineOrder,
//     };
//     updatedMedicineOrder[payload.section] = payload.content;

//     await updateUserData(uid, 'medicineOrder', updatedMedicineOrder);

//     return updatedMedicineOrder;
//   },
// );

export const saveMedicineOrder = createAsyncThunk(
  'myMedicines/saveMedicineOrder',
  async (payload, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const state = getState();
    const uid = state.control.auth.uid;

    const result = await updateUserData(uid, 'medicineOrder', payload);

    if (result instanceof Error) {
      return rejectWithValue('failed');
    }

    return payload;
  },
);

export const myMedicineSlice = createSlice({
  name: 'myMedicines',
  initialState: {
    pickedTrees: {},
    panes: {
      activeKey: '',
      data: [],
    },
    pickedMedicines: [],
    medicineOrder: {},
    isSaving: {
      pickedTrees: false,
      pickedMedicines: false,
      medicineOrder: false,
    },
  },
  reducers: {
    loadUserData: (state, action) => {
      for (let key in state) {
        if (action.payload?.[key]) {
          state[key] = action.payload?.[key];
        }
      }

      return state;
    },
    addPickedTree: (state, action) => {
      const { key, section } = action.payload;
      const currentCheckdTrees = current(state).pickedTrees[key] || {};
      const { checked = [], halfChecked = [] } = currentCheckdTrees;
      const updatedChecked = [...checked];
      const updatedHalfChecked = [...halfChecked];

      if (!updatedChecked.includes(section)) {
        updatedChecked.push(section);
      }

      const halfCheckedArr = [key];
      let currentSection = section.split('-').slice(2);
      currentSection.pop();
      currentSection.reduce((acc, cur) => {
        const next = acc + '-' + cur;
        halfCheckedArr.push(next);
        return next;
      }, key);

      // while (currentSection.length > 4) {
      //   currentSection = currentSection.slice(0, currentSection.length - 2);
      //   halfCheckedArr.push(currentSection);
      // }
      // console.log(halfCheckedArr);|

      halfCheckedArr.forEach((targetSection) => {
        const hasSection = updatedHalfChecked.includes(
          (section) => section === targetSection,
        );

        if (!hasSection) {
          updatedHalfChecked.push(targetSection);
        }
      });
      // console.log('halfChecked', halfChecked);

      // if (!halfChecked?.includes(key)) {
      //   halfChecked.push(key);
      // }
      // console.log('check', checked, halfChecked);

      state.pickedTrees = {
        ...state.pickedTrees,
        [key]: {
          checked: [...updatedChecked],
          halfChecked: [...updatedHalfChecked],
        },
      };
    },
    updatePickedTrees: (state, action) => {
      const { key, checked, halfChecked } = action.payload;
      state.pickedTrees = {
        ...state.pickedTrees,
        [key]: { checked, halfChecked },
      };
    },
    addPane: (state, action) => {
      const key = generateKey(action.payload.section);
      state.panes.data = state.panes.data.filter(
        (pane) => pane.title !== action.payload.title,
      );
      state.panes.data.push({ ...action.payload, key });
      state.panes.activeKey = key;
    },
    updatePanes: (state, action) => {
      state.panes.data = [...action.payload.data];
      state.panes.activeKey = action.payload.activeKey;
    },
    addPickedMedicine: (state, action) => {
      state.pickedMedicines = [...state.pickedMedicines, action.payload];
    },
    removePickedMedicine: (state, action) => {
      state.pickedMedicines = state.pickedMedicines.filter(
        (key) => key !== action.payload,
      );
    },
    selectAllPickedMedicines: (state, action) => {
      const set = new Set([...state.pickedMedicines, ...action.payload]);
      state.pickedMedicines = Array.from(set);
    },
    removeAllPickedMedicines: (state, action) => {
      state.pickedMedicines = state.pickedMedicines.filter(
        (key) => !action.payload.includes(key),
      );
    },
    updateMedicineOrder: (state, action) => {
      const updatedOrder = { ...state.medicineOrder };
      updatedOrder[action.payload.section] = action.payload.content;
      state.medicineOrder = updatedOrder;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(updatePickedTrees.fulfilled, (state, action) => {
    //   state.pickedTrees = action.payload;
    // });
    builder.addCase(savePickedTrees.pending, (state, action) => {
      state.isSaving.pickedTrees = true;
    });
    builder.addCase(savePickedTrees.fulfilled, (state, action) => {
      state.pickedTrees = action.payload;
      state.isSaving.pickedTrees = false;
    });
    // builder.addCase(addPickedMedicine.fulfilled, (state, action) => {
    //   state.pickedMedicines = action.payload;
    // });
    // builder.addCase(removePickedMedicine.fulfilled, (state, action) => {
    //   state.pickedMedicines = action.payload;
    // });
    // builder.addCase(selectAllPickedMedicines.fulfilled, (state, action) => {
    //   state.pickedMedicines = action.payload;
    // });
    // builder.addCase(removeAllPickedMedicines.fulfilled, (state, action) => {
    //   state.pickedMedicines = action.payload;
    // });
    builder.addCase(savePickedMedicines.pending, (state, action) => {
      state.isSaving.pickedMedicines = true;
    });
    builder.addCase(savePickedMedicines.fulfilled, (state, action) => {
      state.pickedMedicines = action.payload;
      state.isSaving.pickedMedicines = false;
    });
    // builder.addCase(updateMedicineOrder.fulfilled, (state, action) => {
    //   state.medicineOrder = action.payload;
    // });
    builder.addCase(saveMedicineOrder.pending, (state, action) => {
      state.isSaving.medicineOrder = true;
    });
    builder.addCase(saveMedicineOrder.fulfilled, (state, action) => {
      state.medicineOrder = action.payload;
      state.isSaving.medicineOrder = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  loadUserData,
  addPickedTree,
  updatePickedTrees,
  addPane,
  updatePanes,
  addPickedMedicine,
  removePickedMedicine,
  selectAllPickedMedicines,
  removeAllPickedMedicines,
  updateMedicineOrder,
} = myMedicineSlice.actions;

export default myMedicineSlice.reducer;
