import React, { useState, useRef, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useFloating, autoUpdate, offset, shift } from '@floating-ui/react';

export const TableModal = ({ title, content }) => {
  let [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef();

  const { x, y, strategy, refs, context } = useFloating({
    placement: 'left',
    middleware: [offset(), shift({ padding: 70 })],
    whileElementsMounted: autoUpdate,
  });

  const handleEnter = (isOpen) => {
    clearTimeout(timeoutRef.current);
    !isOpen && setIsOpen(true);
  };

  const handleLeave = (isOpen) => {
    timeoutRef.current = setTimeout(() => !isOpen && setIsOpen(false), 50);
  };

  return (
    <Popover className="relative w-full">
      {({ open }) => (
        <span
          className="relative w-full"
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}>
          <Popover.Button
            className="hover:underline hover:text-green-400 cursor-default pr-40"
            ref={refs.setReference}>
            {title}
          </Popover.Button>

          {isOpen && (
            <Popover.Panel
              className="whitespace-pre-wrap w-[600px] max-h-[600px] overflow-y-auto overscroll-none px-6 py-4 rounded bg-slate-100 border text-black z-10"
              static
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x + 700 ?? 0,
              }}>
              {content || '無內容'}
            </Popover.Panel>
          )}
        </span>
      )}
    </Popover>
  );
};
