import React from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import styles from './MenuItem.module.css';

export const MenuItem = ({ label, handleClick, path, children }) => {
  const { pathname } = useLocation();

  return (
    <li
      onClick={handleClick}
      className={clsx(
        styles['menu-item'],
        'w-full xl:w-20 2xl:w-24 h-16 px-4 xl:px-0 inline-block text-center font-medium xl:font-bold cursor-pointer hover:bg-control-blue-active hover:text-white transition ease-in-out duration-300',
        pathname === path
          ? 'bg-control-blue-active text-white'
          : 'text-slate-400',
      )}>
      <span className="">{label}</span>
      {children}
    </li>
  );
};
