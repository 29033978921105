import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Tooltip, notification } from 'antd';
import { Menu, Transition, Popover } from '@headlessui/react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

const PanelSegmentPopoverLeaf = ({
  setSegment,
  resetChild,
  type,
  title,
  close,
  direction,
}) => {
  const handleClick = () => {
    if (type === 'parent' && !!resetChild) {
      resetChild('');
    }
    setSegment(title);
    close();
  };

  return (
    <Tooltip placement={direction === 'right' ? 'right' : 'left'} title={title}>
      <div
        className={clsx(
          'w-[144px] h-10 flex items-center p-2',
          type == 'parent' && 'bg-blue-400',
          type == 'child' && 'bg-teal-400',
        )}
        onClick={handleClick}>
        <span className="font-bold text-xl truncate">{title}</span>
      </div>
    </Tooltip>
  );
};

export const PanelSegmentPopoverButton = ({
  className,
  segmentData,
  type,
  segment,
  setSegment,
  resetChild,
  disabled,
  open,
  direction = 'right',
}) => {
  const buttonRef = useRef();

  const coord = buttonRef?.current?.getBoundingClientRect();
  const showRight = window.innerWidth - coord?.right > 40;

  useEffect(() => {
    setSegment('');
  }, [open]);

  const handleClick = () => {
    if (!segmentData?.length) {
      notification.info({
        message: '無分類資料',
        description: `目前分類中無相關資料，請新增後重新嘗試`,
        duration: 2,
      });
    }
  };

  return (
    <Popover className={clsx('relative w-full flex items-center', className)}>
      <Popover.Button
        className={clsx(
          'w-full h-11 p-2',
          type == 'parent' && 'bg-blue-400',
          type == 'child' && 'bg-teal-400',
        )}
        ref={buttonRef}
        disabled={disabled}
        onClick={handleClick}>
        <Tooltip
          placement={direction === 'right' ? 'right' : 'left'}
          title={segment}>
          <div className="w-full truncate">
            <span className="font-bold text-xl truncate">{segment}</span>
          </div>
        </Tooltip>
      </Popover.Button>

      {segmentData?.length > 0 && (
        <Popover.Panel
          className={clsx(
            'absolute top-[-10px] z-10 flex flex-col gap-2 p-2 border border-black bg-white max-h-52 overflow-x-hidden',
            direction == 'left' && 'right-[160px]',
            direction == 'right' && 'left-[156px]',
          )}>
          {({ close }) =>
            segmentData?.map((segment, idx) => (
              <PanelSegmentPopoverLeaf
                key={idx}
                type={type}
                title={segment.title}
                setSegment={setSegment}
                resetChild={resetChild}
                close={close}
                direction={direction}
              />
            ))
          }
        </Popover.Panel>
      )}
    </Popover>
  );
};

export const PanelSegmentPopover = ({
  shortcutData,
  currentSegment,
  segmentData,
  index,
  updateSegmentShortcut,
  handleDisplayCustomized,
  isEditing,
}) => {
  const buttonRef = useRef();
  const [parent, setParent] = useState('');
  const [child, setChild] = useState('');
  const [segmentShortcut, setSegmentShortcut] = useState('');
  const {
    parentSegment: currentParentSegment,
    childSegment: currentChildSegment,
  } = currentSegment;
  const { parentSegment, childSegment } = shortcutData;
  const isActive =
    currentParentSegment === parentSegment &&
    currentChildSegment === childSegment;
  const coord = buttonRef?.current?.getBoundingClientRect();
  const showRight = window.innerWidth - coord?.right > 400;

  const parentSegmentData = segmentData;
  const childSegmentData = parentSegmentData?.find(
    (segment) => segment.title === parent,
  )?.childSegments;

  const handleRemove = () => {
    updateSegmentShortcut({
      parentSegment: null,
      childSegment: null,
      index,
    });
  };

  useEffect(() => {
    if (parent && child) {
      updateSegmentShortcut({
        parentSegment: parent,
        childSegment: child,
        index,
      });
    }
  }, [parent, child]);

  if (childSegment) {
    return (
      <div
        className={clsx(
          'relative flex justify-center items-center w-full bg-slate-300 px-4 py-2 text-lg cursor-pointer',
          isActive && 'ring ring-teal-400',
        )}
        onClick={() =>
          handleDisplayCustomized({
            parent: parentSegment,
            child: childSegment,
          })
        }>
        <Tooltip placement="left" title={childSegment} arrow={false}>
          <div className="flex justify-center w-3/4">
            <span className="truncate text-xl font-semibold">
              {childSegment}
            </span>
          </div>
        </Tooltip>

        {isEditing && (
          <XMarkIcon
            className="absolute right-4 h-6 w-6"
            onClick={() => handleRemove(index)}
          />
        )}
      </div>
    );
  }

  return (
    <div className={clsx('h-11 bg-slate-300 px-4 py-2')} ref={buttonRef}>
      <Popover className="relative w-full flex items-center">
        {({ open }) => (
          <div className={'w-full'}>
            {isEditing && (
              <Popover.Button className={clsx('w-full flex justify-center')}>
                <PlusIcon className="h-6 w-6" />
              </Popover.Button>
            )}
            <Popover.Panel
              className={clsx(
                'absolute right-[-100px] top-[-10px] z-10 flex flex-col gap-2 p-2 border border-black bg-white w-40',
                !showRight && 'left-[-100px]',
              )}>
              <PanelSegmentPopoverButton
                type="parent"
                segmentData={parentSegmentData}
                segment={parent}
                setSegment={setParent}
                open={open}
                direction={showRight ? 'right' : 'left'}
              />
              <PanelSegmentPopoverButton
                type="child"
                segmentData={childSegmentData}
                segment={child}
                disabled={!parent}
                setSegment={setChild}
                open={open}
                direction={showRight ? 'right' : 'left'}
              />
            </Popover.Panel>
          </div>
        )}
      </Popover>
    </div>
  );
};
