import React from 'react';
import { Tag, Tooltip } from 'antd';
import clsx from 'clsx';

export const SearchHistory = ({
  historyData,
  handleClick,
  handleClose,
  handleRemoveAll,
  disabled,
  col,
  mode,
}) => {
  let tagPlacement = (idx) => {
    return idx % 2 === 0 ? 'left' : 'right';
  };

  if (mode === 'MEDICINE') {
    tagPlacement = (idx) => {
      return idx < 4 ? 'top' : 'bottom';
    };
  }
  return (
    <div className="flex justify-center">
      <div
        className={clsx(
          'w-full h-full grid gap-2',
          `grid-cols-${col}`,
          mode !== 'MEDICINE' && 'pt-5',
        )}>
        {historyData &&
          historyData.map((h, idx) => (
            <Tooltip
              key={`${h}-${idx}`}
              placement={tagPlacement(idx)}
              title={h}>
              <Tag
                closable
                className="history-tag"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick(h)}
                onClose={() => handleClose(h)}>
                <span
                  className={clsx(
                    'w-4/5 p-2 truncate',
                    disabled && 'cursor-not-allowed',
                  )}>
                  {h}
                </span>
              </Tag>
            </Tooltip>
          ))}
        {/* {!!historyData.length && (
        <Tag
          className="history-tag flex"
          style={{
            cursor: 'pointer',
          }}
          onClick={handleRemoveAll}>
          <span className="">清除全部</span>
        </Tag>
      )} */}
      </div>
    </div>
  );
};
