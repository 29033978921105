import React from 'react';
import { Head } from '@/components/Head';
import { ContentLayout } from '@/components/Layout';
import { useScrollToTop } from '@/hooks/useScrollToTop';
import './Terms.css';

export const Terms = () => {
  useScrollToTop();

  return (
    <>
      <Head title="服務條款" description="藥方便，你的回饋是我們進步的動力" />
      <ContentLayout>
        <div className="flex justify-center text-xl leading-9">
          <div className="w-3/5">
            <div className="py-6">
              <h5 className="text-lg font-bold">使用條款及免責聲明</h5>
              <h4 className="text-base">生效日：2022年8月29日</h4>
            </div>
            <h2 className="text-3xl mb-10">
              本網站由「藥方便」所經營。本網站重視每個會員所應享有的權益，特此說明本網站的使用政策，請各位細讀本使用條款的內容：
            </h2>
            <ol>
              <li className="list-decimal	list-outside mb-8">
                關於《使用條款》
                <ol>
                  <li type="A" className="list-outside">
                    在使用本網站的服務前，請仔細閱讀使用條款。您必須在同意以下條款的前提下，才能使用本服務。當使用條款與其他附加條件或其他特殊條款相矛盾時，以附加條件或特殊條款為準。
                  </li>
                  <li type="A" className="list-outside">
                    本網站有權於任何時間修改或變更本使用條款之內容。若您於本網站修改使用條款後仍繼續使用本服務，將視為您已同意接受條款之變更。
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                服務內容
                <ol>
                  <li type="A" className="list-outside">
                    服務之具體內容由本網站根據實際情況提供，並擁有對其所提供服務之最終解釋權。
                  </li>
                  <li type="A" className="list-outside">
                    本服務須透過網際網路使用，您必須自行配備上網所需的各項電腦設備，並承擔所需的費用。
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                授權
                <ol>
                  <li type="A" className="list-outside">
                    在本使用條款的約束下，本網站僅授予您有限，僅供個人使用且不可轉讓的使用本服務。
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                使用者的行為
                <ol>
                  <li type="A" className="list-outside">
                    您在使用本網站服務過程中，必須遵循以下原則：
                    <ol>
                      <li type="i" className="list-outside">
                        遵守中華民國有關的法律和法規
                      </li>
                      <li type="i" className="list-outside">
                        遵守所有與網路服務有關的網路協定、規定和程式；
                      </li>
                      <li type="i" className="list-outside">
                        不得為任何非法目的而使用網路服務系統；
                      </li>
                      <li type="i" className="list-outside">
                        不使用任何設備、軟體或程序，干擾或試圖干擾本網站、本網站之正常運作；
                      </li>
                      <li type="i" className="list-outside">
                        不允許侵入、強行入侵、接通、使用或企圖侵入、接通、使用本網站伺服器及未經本網站對您發出許可的任何資料區；
                      </li>
                      <li type="i" className="list-outside">
                        不得限制、制止其他使用者使用並享用本服務；
                      </li>
                      <li type="i" className="list-outside">
                        如發現任何非法使用帳號或任何出現安全漏洞的情況，應立即告知本網站。
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                知識產權
                <ol>
                  <li type="A" className="list-outside">
                    本網站提供的服務內容，均由本網站或其他相關權利人依法所擁有，並受商業形象、著作權法、商標法、公平交易法以及各類智慧財產權法之保護。
                  </li>
                  <li type="A" className="list-outside">
                    非經本網站或其他相關權利人之事先書面同意，本網站及其非開放性編輯的內容，不能修改、複製、重製、重印、公開播送、改作、散佈、發行、公開發表及為任何非法目的。
                  </li>
                  <li type="A" className="list-outside">
                    經本網站或其他相關權利人之事先書面同意，您在修改、複製、重製、重印、公開播送、改作、散佈、發行、公開發表本網站及其內容之任何部分時，必須詳細標明本網站之名稱及網址為其資料來源。
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                連結
                <ol>
                  <li type="A" className="list-outside">
                    本網站所提供之所有連結，可能連結到其他個人、公司或組織之網站，提供該等連結之目的，僅為便利使用者取得資訊，本網站對於被連結之該等個人、公司或組織之網站上所提供之產品、服務或資訊，既不擔保其真實性、完整性、即時性或可信度，該等個人、公司或組織亦不因此而當然與本網站有任何僱佣、委任、代理、合夥或其他類似之關係。
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                違約賠償
                <ol>
                  <li type="A" className="list-outside">
                    您同意保障和維護本網站及任何其他第三方的利益，如因違反有關法律、法規或本使用條款項下的任何條款而致使本網站或任何其他第三方造成損失，您同意承擔所造成損害之賠償責任。
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                拒絕提供擔保
                <ol>
                  <li type="A" className="list-outside">
                    本網站不提供任何明示或暗示的擔保，包含但不限於商業適售性、特定目的之適用性及未侵害他人權利。
                  </li>
                  <li type="A" className="list-outside">
                    本網站不擔保以下事項：
                    <ol>
                      <li type="i" className="list-outside">
                        本服務將符合您的需求；
                      </li>
                      <li type="i" className="list-outside">
                        本服務不受干擾、即時提供、安全可靠或從不出錯；
                      </li>
                      <li type="i" className="list-outside">
                        經由本服務之使用而取得任何資料之合適性、有效性、正確性、完整性、安全性、可靠性、即時性及是否侵害他人權利。
                      </li>
                    </ol>
                  </li>
                  <li type="A" className="list-outside">
                    若您透過本網站與網路上其他廠商或使用者進行商品或服務之買賣或各種交易行為，此時僅該廠商或使用者與您有契約關係存在，本網站並不介入或參與。如該商品或服務有任何瑕疵或糾紛時，請您逕行與該廠商或使用者聯絡解決，本網站對此不負任何擔保責任。
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                免責聲明
                <ol>
                  <li type="A" className="list-outside">
                    本網站承諾盡力確保內容準確性與適當，但醫學發展快速，故不能保證資訊完全無誤，如若有誤植或遺漏內容，將盡速更新資料與修正錯誤。本網站提供之服務為藥物輔助，僅供參考協助之用，而非直接提供診斷或治療，臨床藥物使用及決策仍須專業醫療人員之判斷與開立，本網站不負責任何擔保行為或賠償責任。
                  </li>
                  <li type="A" className="list-outside">
                    在任何情況下，本網站對於您或任何其他第三方因使用或不能使用該等服務所發生的任何直接的、間接的、附帶引起的、特別的、懲罰性的或者間接性的損失或損害賠償，不承擔任何責任。即使本網站已知悉可能會有該等損失或損害賠償，亦是如此。
                  </li>
                  <li type="A" className="list-outside">
                    本網站因於下列情形之一時，得暫停或中斷本服務之部分或全部服務，且對使用者任何直接或間接之損害，不承擔任何責任：
                    <ol>
                      <li type="i" className="list-outside">
                        對於本網站相關系統設備進行遷移、更換或維護時；
                      </li>
                      <li type="i" className="list-outside">
                        任何不可歸責於之事由所造成之服務停止或中斷；
                        <ul>
                          <li>
                            其他已明確可歸責於本網站之事而導致服務停止或中斷，您有權決定退出，並且要求退回相對比例的年費，比例將以未使用之月份計算；入會費不予以退回。
                          </li>
                        </ul>
                      </li>
                      <li type="i" className="list-outside">
                        天災或其他不可抗力造成本網站之服務停止或中斷；
                      </li>
                      <li type="i" className="list-outside">
                        使用者有任何違反政府法令或本使用條款情事。
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                法律管轄
                <ol>
                  <li type="A" className="list-outside">
                    本使用條款的訂立、執行和解釋及爭議的解決均應適用於中華民國法律，並以台灣台北地方法院作為第一審管轄法院。
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                其他規定
                <ol>
                  <li type="A" className="list-outside">
                    本使用條款構成雙方對本服務及其他有關事宜的完整協議，並取代雙方先前就本使用條款所為之任何形式之承諾或協議。
                  </li>
                  <li type="A" className="list-outside">
                    如本使用條款中的任何條款無論因何種原因，完全或部分無效或不具有執行力，本使用條款的其餘條款仍應有效並且有約束力。
                  </li>
                  <li type="A" className="list-outside">
                    本使用條款的標題僅為方便閱讀所設，非對條款的定義、限制、解釋或描述其範圍或界限。
                  </li>
                </ol>
              </li>
              <li className="list-decimal	list-outside mb-8">
                聯絡方式
                <ol>
                  <li type="A" className="list-outside">
                    若您對於本使用條款或本網站有任何問題或建議，歡迎來信至我們的服務信箱。
                  </li>
                  <li type="A" className="list-outside">
                    E-mail：medicalhelperweb@gmail.com
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};
