import { auth } from './index';
import {
  setPersistence,
  browserLocalPersistence,
  inMemoryPersistence,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
} from 'firebase/auth';

// const setLoginPersistence = async (isPersistence) => {
//   const persistenceType = isPersistence
//     ? browserLocalPersistence
//     : inMemoryPersistence;
//   // console.log('setPersistence', persistenceType);
//   try {
//     const result = await setPersistence(auth, persistenceType);
//   } catch (error) {
//     console.log(error);
//   }
// };

// const loginEmailPassword = async (loginEmail, loginPassword) => {
//   try {
//     const userCredential = await signInWithEmailAndPassword(
//       auth,
//       loginEmail,
//       loginPassword,
//     );

//     return userCredential;
//   } catch (error) {
//     return error.code;
//   }
// };

const createUser = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );

    return userCredential;
  } catch (error) {
    console.log(error);
    return error.code;
  }
};

const monitorAuthState = async (callback) => {
  return onAuthStateChanged(auth, callback);
};

const getCustomClaims = async () => {
  const idTokenResult = await auth.currentUser.getIdTokenResult();
  const { role, expiration } = await idTokenResult.claims;
  // console.log('claims', idTokenResult.claims);
  return { role, expiration };
};

const refreshCustomClaims = async () => {
  await auth.currentUser.getIdToken(true);
  const idTokenResult = await auth.currentUser.getIdTokenResult();
  const { role, expiration } = idTokenResult.claims;

  return { role, expiration };
};

const logOut = async () => {
  await signOut(auth);
};

// can not work under emulator?
const resetPassword = async (email) => {
  await sendPasswordResetEmail(auth, email);
};

const verifyEmail = async () => {
  await sendEmailVerification(auth.currentUser);
};

export {
  // setLoginPersistence,
  // loginEmailPassword,
  monitorAuthState,
  getCustomClaims,
  refreshCustomClaims,
  createUser,
  logOut,
  resetPassword,
  verifyEmail,
};
