import { notification } from 'antd';
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { updateUserData } from '@/libs/firebase/database';

export const saveICDUserData = createAsyncThunk(
  'ICDSearch/saveICDUserData',
  async (payload, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const state = getState();
    const uid = state.control.auth.uid;
    const {
      searchControlPanel = [],
      segments = [],
      mostUsed = [],
      mostUsedOrder = {},
      pickedICD = [],
      myICDNote = {},
    } = state.ICDSearch;
    const { segmentShortcuts } = searchControlPanel;

    const ICDUserData = {
      segmentShortcuts,
      segments,
      mostUsed,
      mostUsedOrder,
      pickedICD,
      myICDNote,
    };
    const result = await updateUserData(uid, 'ICDUserData', ICDUserData);

    if (result instanceof Error) {
      return rejectWithValue('failed');
    }

    return payload;
  },
);

export const addSearchKeyword = createAsyncThunk(
  'ICDSearch/addSearchKeyword',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;
    let newKeywords = state.ICDSearch.searchHistory.filter(
      (keyword) => keyword !== payload,
    );
    newKeywords.unshift(payload);

    if (newKeywords.length > 8) {
      newKeywords = newKeywords.slice(0, 8);
    }

    await updateUserData(uid, 'ICDSearchHistory', newKeywords);

    return newKeywords;
  },
);

export const removeSearchKeyword = createAsyncThunk(
  'ICDSearch/removeSearchKeyword',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;

    let newSearchHistory = state.ICDSearch.searchHistory.filter(
      (keyword) => keyword !== payload,
    );

    await updateUserData(uid, 'ICDSearchHistory', newSearchHistory);

    return newSearchHistory;
  },
);

export const updateSearchKeyword = createAsyncThunk(
  'ICDSearch/updateSearchKeyword',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;

    let newSearchHistory = payload;

    await updateUserData(uid, 'ICDSearchHistory', newSearchHistory);

    return newSearchHistory;
  },
);

export const ICDSearchSlice = createSlice({
  name: 'ICDSearch',
  initialState: {
    searchHistory: [],
    isSaving: {
      searchHistory: false,
    },
    searchControlPanel: {
      mode: 'ALL',
      currentSegment: {
        parentSegment: '',
        childSegment: '',
      },
      segmentShortcuts: [],
    },
    segments: [],
    mostUsed: [],
    mostUsedOrder: {},
    pickedICD: [],
    myICDNote: {},
  },
  reducers: {
    loadICDSearchHistory: (state, action) => {
      state.searchHistory = action.payload?.ICDSearchHistory || [];
    },

    loadICDUserData: (state, action) => {
      const {
        segmentShortcuts,
        segments,
        mostUsed,
        mostUsedOrder,
        pickedICD,
        myICDNote,
      } = action.payload.ICDUserData || {};

      const defaultShortcuts = new Array(16).fill('').map((i, idx) => ({
        index: idx,
        parentSegment: null,
        childSegment: null,
      }));

      state.searchControlPanel.segmentShortcuts =
        segmentShortcuts || defaultShortcuts;
      state.segments = segments || [];
      state.mostUsed = mostUsed || [];
      state.mostUsedOrder = mostUsedOrder || {};
      state.pickedICD = pickedICD || [];
      state.myICDNote = myICDNote || {};
    },
    setMode: (state, action) => {
      state.searchControlPanel.mode = action.payload;
    },
    setParentSegment: (state, action) => {
      state.searchControlPanel.currentSegment.parentSegment = action.payload;
    },
    setChildSegment: (state, action) => {
      state.searchControlPanel.currentSegment.childSegment = action.payload;
    },
    addParentSegment: (state, action) => {
      const parent = action.payload;
      const segments = current(state.segments);
      const index = segments.findIndex((segment) => segment.title === parent);

      if (index > 0) {
        return notification.warning({
          message: '名稱重複',
          description: `${parent}已存在，請改用其他名稱`,
          duration: 2,
        });
      }

      const currentSegmentLength = segments.length;

      state.segments.push({
        title: parent,
        sortingOrder: currentSegmentLength,
        childSegments: [],
      });
    },
    addChildSegment: (state, action) => {
      const child = action.payload;
      const segments = current(state.segments);
      const { parentSegment } = current(
        state.searchControlPanel.currentSegment,
      );

      if (!parentSegment) {
        return;
      }

      const index = segments.findIndex(
        (segment) => segment.title === parentSegment,
      );

      if (index < 0) {
        return;
      }

      const pSegment = current(state.segments[index]);

      if ('childSegments' in pSegment) {
        const childSegmentsLength = segments[index]?.childSegments?.length;

        state.segments[index].childSegments.push({
          title: child,
          sortingOrder: childSegmentsLength,
          relatedTreatment: { data: [], order: [] },
        });
      } else {
        state.segments[index] = {
          ...pSegment,
          childSegments: [
            {
              title: child,
              sortingOrder: 0,
              relatedTreatment: { data: [], order: [] },
            },
          ],
        };
      }
    },
    updateSegments: (state, action) => {
      const { parentSegment } = current(
        state.searchControlPanel.currentSegment,
      );
      const { data, type } = action.payload;

      if (type === 'child') {
        state.segments.find(
          (segment) => segment.title === parentSegment,
        ).childSegments = data;
      } else {
        state.segments = data;
      }
    },
    removeSegment: (state, action) => {
      const { parentSegment } = current(
        state.searchControlPanel.currentSegment,
      );
      const { segment: title, type } = action.payload;

      if (type === 'child') {
        const segments = current(state.segments);
        const index = segments.findIndex(
          (pSegment) => pSegment.title === parentSegment,
        );
        const filteredSegments = segments[index].childSegments.filter(
          (segment) => {
            return segment.title !== title;
          },
        );

        state.segments[index].childSegments = filteredSegments;
      } else {
        const segments = current(state.segments);
        const index = segments.findIndex(
          (pSegment) => pSegment.title === parentSegment,
        );
        const filteredSegments = segments.filter(
          (segment) => segment.title !== title,
        );
        state.segments = filteredSegments;
      }
    },
    updateSegmentShortcut: (state, action) => {
      const { parentSegment, childSegment, index } = action.payload;

      state.searchControlPanel.segmentShortcuts[index] = {
        index,
        parentSegment,
        childSegment,
      };
    },
    addPickedICD: (state, action) => {
      state.pickedICD = [...state.pickedICD, action.payload];
    },
    removePickedICD: (state, action) => {
      state.pickedICD = state.pickedICD.filter((key) => key !== action.payload);
    },
    selectAllPickedICD: (state, action) => {
      const set = new Set([...state.pickedICD, ...action.payload]);
      state.pickedICD = Array.from(set);
    },
    removeAllPickedICD: (state, action) => {
      state.pickedICD = state.pickedICD.filter(
        (key) => !action.payload.includes(key),
      );
    },
    updateICDNote: (state, action) => {
      const { payload } = action;

      const updatedICDNote = {
        ...state.myICDNote,
        ...payload,
      };

      state.myICDNote = updatedICDNote;
    },
    addCustomizedICD: (state, action) => {
      const { type, parent, child, icd10 } = action.payload;

      if (type === 'MOST_USED') {
        state.mostUsed.push(icd10);
      } else {
        const parentIndex = current(state.segments).findIndex(
          (pSegment) => pSegment.title === parent,
        );
        const childIndex = current(
          state.segments[parentIndex],
        ).childSegments.findIndex((cSegment) => cSegment.title === child);

        const childSegment = current(
          state.segments[parentIndex].childSegments[childIndex],
        );

        if ('relatedICD' in childSegment) {
          state.segments[parentIndex].childSegments[
            childIndex
          ].relatedICD.data.push(icd10);
        } else {
          state.segments[parentIndex].childSegments[childIndex] = {
            ...childSegment,
            relatedICD: { data: [icd10] },
          };
        }
      }
    },
    removeCustomizedICD: (state, action) => {
      const { type, parent, child, icd10 } = action.payload;

      if (type === 'MOST_USED') {
        const filteredICDData = current(state.mostUsed).filter(
          (icd) => icd !== icd10,
        );

        const updatedICDOrder = { ...current(state.mostUsedOrder) };

        if (icd10 in updatedICDOrder) {
          delete updatedICDOrder[icd10];
        }

        state.mostUsed = filteredICDData;
        state.mostUsedOrder = updatedICDOrder;
      } else {
        const parentIndex = current(state.segments).findIndex(
          (pSegment) => pSegment.title === parent,
        );
        const childIndex = current(
          state.segments[parentIndex],
        ).childSegments.findIndex((cSegment) => cSegment.title === child);
        const relatedICD = current(
          state.segments[parentIndex].childSegments[childIndex].relatedICD,
        );
        const filteredICDData = relatedICD.data.filter((icd) => icd !== icd10);

        state.segments[parentIndex].childSegments[childIndex].relatedICD.data =
          filteredICDData;

        // delete ICD icd10 in order if it has
        if ('order' in relatedICD) {
          const order = {
            ...current(
              state.segments[parentIndex].childSegments[childIndex].relatedICD
                .order,
            ),
          };

          if (icd10 in order) {
            delete order[icd10];

            state.segments[parentIndex].childSegments[
              childIndex
            ].relatedICD.order = order;
          }
        }
      }
    },
    updateICDOrder: (state, action) => {
      const { mode, parent, child, content } = action.payload;
      if (mode === 'MOST_USED') {
        state.mostUsedOrder = content;
      } else {
        const parentIndex = current(state.segments).findIndex(
          (pSegment) => pSegment.title === parent,
        );
        const childIndex = current(
          state.segments[parentIndex],
        ).childSegments.findIndex((cSegment) => cSegment.title === child);

        state.segments[parentIndex].childSegments[childIndex].relatedICD.order =
          content;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveICDUserData.pending, (state, action) => {
      state.isSaving = true;
    });
    builder.addCase(saveICDUserData.fulfilled, (state, action) => {
      state.isSaving = false;
    });
    builder.addCase(addSearchKeyword.pending, (state, action) => {
      state.isSaving.searchHistory = true;
    });
    builder.addCase(addSearchKeyword.fulfilled, (state, action) => {
      state.searchHistory = action.payload;
      state.isSaving.searchHistory = false;
    });
    builder.addCase(removeSearchKeyword.pending, (state, action) => {
      state.isSaving.searchHistory = true;
    });
    builder.addCase(removeSearchKeyword.fulfilled, (state, action) => {
      state.searchHistory = action.payload;
      state.isSaving.searchHistory = false;
    });
    builder.addCase(updateSearchKeyword.pending, (state, action) => {
      state.isSaving.searchHistory = true;
    });
    builder.addCase(updateSearchKeyword.fulfilled, (state, action) => {
      state.searchHistory = action.payload;
      state.isSaving.searchHistory = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  loadICDSearchHistory,
  loadICDUserData,
  setMode,
  setParentSegment,
  setChildSegment,
  addParentSegment,
  addChildSegment,
  updateSegments,
  removeSegment,
  updateSegmentShortcut,
  addPickedICD,
  removePickedICD,
  selectAllPickedICD,
  removeAllPickedICD,
  updateICDNote,
  addCustomizedICD,
  removeCustomizedICD,
  updateICDOrder,
} = ICDSearchSlice.actions;

export default ICDSearchSlice.reducer;
