import React, { useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router';
import { AutoComplete, Input } from 'antd';
import clsx from 'clsx';
import styles from './SearchBar.module.css';

export const MedicineSearchBar = ({
  autocompleteData,
  handleSearch,
  handleInput,
  inputValue,
  disabled,
  handleFocus,
  handleBlur,
}) => {
  const tableRef = useRef();
  const { pathname } = useLocation();

  const autocompleteResult = useCallback(
    (query) =>
      autocompleteData
        ?.filter((name) => name.toLowerCase().includes(query))
        ?.map((name, idx) => {
          return {
            value: name,
            label: <span>{name}</span>,
            key: `${name}-${idx}`,
          };
        }),
    [autocompleteData],
  );

  return (
    <div
      className={clsx(
        'history-search-bar search-bar-customInput flex justify-center py-5 pr-2 relative',
      )}
      tabIndex="0"
      ref={tableRef}>
      <Input.Search
        size="large"
        placeholder={disabled ? '' : '請輸入欲搜尋的關鍵字'}
        value={inputValue}
        onChange={(e) => handleInput(e.target.value)}
        onSearch={handleSearch}
        enterButton
        // className={clsx(pathname === '/icd-search' && styles['ICD-search'])}
        // onFocus={() => handleFocus(true)}
        // onBlur={() => handleFocus(false)}
        onFocus={handleFocus}
        onBlur={(e) => handleBlur(e)}
      />
    </div>
  );
};
