import React from 'react';
import clsx from 'clsx';
import { HeaderSegmentDropdown } from './HeaderSegmentDropdown';

export function ControlPanelHeader({
  mode,
  setMode,
  currentSegment,
  segmentData,
  setParentSegment,
  setChildSegment,
  addParentSegment,
  addChildSegment,
  updateSegments,
  removeSegment,
  handleDisplayCustomized,
  isEditing,
}) {
  const { parentSegment, childSegment } = currentSegment;
  const parentSegmentData = segmentData;
  const childSegmentData = parentSegmentData?.find(
    (segment) => segment.title === parentSegment,
  )?.childSegments;

  return (
    <div className="flex gap-4">
      <button
        className={clsx(
          'bg-pink-400 px-4 py-2 font-semibold text-xl',
          mode == 'ALL' && 'ring-2 ring-offset-1 ring-pink-600',
        )}
        onClick={() => setMode('ALL')}>
        All
      </button>
      <button
        className={clsx(
          'bg-green-400 px-4 py-2 font-semibold text-xl',
          mode == 'MOST_USED' && 'ring-2 ring-offset-1 ring-green-600',
        )}
        onClick={() => setMode('MOST_USED')}>
        常用
      </button>
      <HeaderSegmentDropdown
        type="parent"
        className="bg-blue-400 px-4 py-2 font-semibold text-xl"
        isEditing={isEditing}
        setSegment={setParentSegment}
        segmentData={parentSegmentData}
        addSegment={addParentSegment}
        removeSegment={removeSegment}
        updateSegments={updateSegments}>
        {currentSegment.parentSegment}
      </HeaderSegmentDropdown>
      <HeaderSegmentDropdown
        type="child"
        className="bg-teal-400 px-4 py-2 font-semibold text-xl"
        isEditing={isEditing}
        setSegment={setChildSegment}
        segmentData={childSegmentData}
        addSegment={addChildSegment}
        removeSegment={removeSegment}
        updateSegments={updateSegments}
        setMode={setMode}
        // only type child have
        parentSegment={parentSegment}
        handleDisplayCustomized={handleDisplayCustomized}>
        {currentSegment.childSegment}
      </HeaderSegmentDropdown>
    </div>
  );
}
