import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';
import { MainLayout } from '@/components/Layout';
import { Spin } from 'antd';
import './App.css';

const App = () => {
  return (
    <AppProvider>
      <MainLayout>
        <Suspense
          fallback={
            <div className="h-full w-full flex items-center justify-center">
              <Spin size="large" />
            </div>
          }>
          <AppRoutes />
        </Suspense>
      </MainLayout>
    </AppProvider>
  );
};

export default App;
