import { Menu, MenuItem } from '@/components/Elements/Menu';
import {
  clearAuth,
  loadAuth,
  loadDataVersion,
  loadUserPreference,
  saveUserPreference,
  switchBeta,
  switchICDDot,
  switchRelatedWords,
  updateLayout,
} from '@/features/controlPanel/store/controlSlice';
import {
  loadICDSearchHistory,
  loadICDUserData,
} from '@/features/ICDSearch/store/ICDSearchSlice';
import { loadMedicineSearchHistory } from '@/features/medicineSearch/store/medicineSearchSlice';
import {
  loadTreatmentSearchHistory,
  loadTreatmentUserData,
} from '@/features/treatmentSearch/store/treatmentSearchSlice';
import { auth } from '@/libs/firebase';
import {
  CopyOutlined,
  FireOutlined,
  LogoutOutlined,
  MenuOutlined,
  SearchOutlined,
  SettingOutlined,
  ShoppingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Switch } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { BackTop, Drawer, Layout, notification } from 'antd';
import clsx from 'clsx';
import { openDB } from 'idb';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import logo from '@/assets/logo/logo_transparent_small.png';
import { useAuthorization } from '@/features/authorization';
import { ControlPanel } from '@/features/controlPanel';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useUnload } from '@/hooks/useUnload';
import {
  getCustomClaims,
  logOut,
  monitorAuthState,
} from '@/libs/firebase/auth';
import { fetchDataVersion, fetchUserData } from '@/libs/firebase/database';
import styles from './MainLayout.module.css';

const { Header, Content, Footer } = Layout;
const adminEmail = process.env.REACT_APP_API_URL;

const Logo = () => {
  const navigate = useNavigate();
  const isEditingTable = useSelector(
    (state) => state.control.control.isEditingTable,
  );

  const handleClick = () => {
    if (isEditingTable.status) {
      notification.warning({
        message: '無法離開此頁面',
        description: `<${isEditingTable.editingRowName}> 尚未儲存，請確認儲存後再次操作`,
        duration: 5,
      });
      return;
    }
    navigate('/');
  };

  return (
    <div
      className="w-40 flex items-center mr-2 cursor-pointer"
      onClick={handleClick}>
      <img src={logo} className="h-16 w-40 object-cover" alt="logo" />
    </div>
  );
};

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { checkAccess } = useAuthorization();
  const [activeNav, setActiveNav] = useState('');
  const isEditingTable = useSelector(
    (state) => state.control.control.isEditingTable,
  );

  useEffect(() => {
    setActiveNav('');
  }, [location]);

  const navigation = [
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '0',
      label: '藥物總表',
      path: '/medicines',
    },
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '1',
      label: '自選藥物',
      path: '/my-medicines',
    },
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '2',
      label: '藥物搜尋',
      path: '/medicine-search',
    },
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '3',
      label: '診斷碼搜尋',
      path: '/icd-search',
    },
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '4',
      label: '處置及檢驗',
      path: '/treatment-search',
    },
    checkAccess({ allowedRoles: ['ADMIN'] }) && {
      key: '5',
      label: '用戶反饋',
      path: '/feedbacks',
    },
    checkAccess({ allowedRoles: ['ADMIN'] }) && {
      key: '6',
      label: '付款信息',
      path: '/purchaseInfo',
    },
    checkAccess({ allowedRoles: ['ADMIN'] }) && {
      key: '7',
      label: '用戶權限',
      path: '/admin',
    },
  ];

  const handleNavClick = (key, items) => {
    if (activeNav === key) {
      return;
    }
    if (isEditingTable.status) {
      notification.warning({
        message: '無法離開此頁面',
        description: `<${isEditingTable.editingRowName}> 尚未儲存，請確認儲存後再次操作`,
        duration: 5,
      });
      return;
    }

    setActiveNav(key);
    navigate(items[key].path);
  };

  const content = navigation
    .filter(Boolean)
    .map((item) => (
      <MenuItem
        label={item.label}
        path={item.path}
        handleClick={() => handleNavClick(item.key, navigation)}
        key={item.key}
      />
    ));

  return <Menu>{content}</Menu>;
};

const SideBar = ({ closeDrawer }) => {
  const navigate = useNavigate();
  const { checkAccess } = useAuthorization();
  const [activeNav, setActiveNav] = useState('');
  const isEditingTable = useSelector(
    (state) => state.control.control.isEditingTable,
  );

  const navigation = [
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '0',
      label: '首頁',
      path: '/',
    },
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '1',
      label: '藥物總表',
      path: '/medicines',
    },
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '2',
      label: '自選藥物',
      path: '/my-medicines',
    },
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '3',
      label: '藥物搜尋',
      path: '/medicine-search',
    },
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '4',
      label: '診斷碼搜尋',
      path: '/icd-search',
    },
    checkAccess({ allowedRoles: ['TRIAL', 'PREMIUM', 'ADMIN'] }) && {
      key: '5',
      label: '處置及檢驗',
      path: '/treatment-search',
    },
    checkAccess({ allowedRoles: ['ADMIN'] }) && {
      key: '6',
      label: '用戶反饋',
      path: '/feedbacks',
    },
    checkAccess({ allowedRoles: ['ADMIN'] }) && {
      key: '7',
      label: '付款信息',
      path: '/purchaseInfo',
    },
    checkAccess({ allowedRoles: ['ADMIN'] }) && {
      key: '8',
      label: '用戶權限',
      path: '/admin',
    },
  ];

  const handleNavClick = (key, items) => {
    if (activeNav === key) {
      return;
    }
    if (isEditingTable.status) {
      notification.warning({
        message: '無法離開此頁面',
        description: `<${isEditingTable.editingRowName}> 尚未儲存，請確認儲存後再次操作`,
        duration: 5,
      });
      closeDrawer();

      return;
    }
    setActiveNav(key);
    closeDrawer();
    navigate(items[key].path);
  };

  const content = navigation
    .filter(Boolean)
    .map((item) => (
      <MenuItem
        label={item.label}
        handleClick={() => handleNavClick(item.key, navigation)}
        path={item.path}
        key={item.key}
      />
    ));

  return <Menu>{content}</Menu>;
};

const NavDrawer = ({ userEmail, hidden }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
        bodyStyle={{ backgroundColor: '#001529', padding: '0px' }}
        contentWrapperStyle={{ color: 'white' }}
        width="200px">
        {userEmail && <SideBar closeDrawer={() => setSidebarOpen(false)} />}
      </Drawer>
      {userEmail && (
        <button
          className={clsx(
            'block xl:hidden px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500',
            hidden && 'hidden',
          )}
          onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <MenuOutlined />
        </button>
      )}
    </>
  );
};

const RightNav = ({ userEmail }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef();
  const { relatedWordsEnabled, ICDWithDot, betaEnabled } = useSelector(
    (state) => state.control.userPreference,
  );
  const isAdmin = useSelector((state) => state.control.auth.role) === 'ADMIN';
  const hasBetaMembership = useSelector(
    (state) => state.control.auth.hasBetaMembership,
  );

  useEffect(() => {
    const closeDropDown = (e) => {
      if (!navRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener('click', closeDropDown);

    return () => document.body.removeEventListener('click', closeDropDown);
  }, []);

  const handleRelatedWords = useCallback(() => {
    dispatch(switchRelatedWords());
    dispatch(saveUserPreference());
  }, [switchRelatedWords]);

  const handleICDDot = useCallback(() => {
    dispatch(switchICDDot());
    dispatch(saveUserPreference());
  }, [switchICDDot]);

  const handleBetaEnabled = useCallback(() => {
    dispatch(switchBeta());
    dispatch(saveUserPreference());
  });

  const handleRoute = (route) => {
    setIsOpen(false);
    navigate(route);
  };

  const handleLogOut = () => {
    setIsOpen(false);
    dispatch(clearAuth());
    logOut();
    navigate('/');
  };

  // const handleCloseMenu = (e) => {
  //   if (e.target )
  // }

  let content;

  if (userEmail) {
    content = (
      <Menu>
        <MenuItem
          handleClick={() => {
            setIsOpen(!isOpen);
          }}>
          <UserOutlined className="text-2xl" />
        </MenuItem>
        {isOpen && (
          <ul
            className="absolute top-[66px] right-0 w-72 p-4 px-8 border-violet-800 rounded-lg bg-control-blue"
            // on
            // onBlur={() => setIsOpen(false)}
          >
            <li className="list-none text-center text-white font-bold tracking-wider leading-6">
              {userEmail}
            </li>
            <div className="h-12 list-none text-md text-white font-bold tracking-wider cursor-pointer hover:text-blue-400">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <CopyOutlined className="text-xl mr-2" />
                  ICD10 小數點
                </div>
                <Switch.Group
                  as="li"
                  className="flex items-center justify-between py-4">
                  <Switch
                    checked={ICDWithDot}
                    onChange={handleICDDot}
                    className={clsx(
                      ICDWithDot ? 'bg-teal-500' : 'bg-gray-200',
                      'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2',
                    )}>
                    <span
                      aria-hidden="true"
                      className={clsx(
                        ICDWithDot ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
            <div className="h-12 list-none text-md text-white font-bold tracking-wider cursor-pointer hover:text-blue-400">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <SearchOutlined className="text-xl mr-2" />
                  關聯詞
                </div>
                <Switch.Group
                  as="li"
                  className="flex items-center justify-between py-4">
                  {/* <div className="flex flex-col">
                    <Switch.Label
                      as="p"
                      className="text-sm font-medium leading-6 text-gray-900"
                      passive>
                      開啟關聯詞搜尋
                    </Switch.Label>
                    <Switch.Description className="text-sm text-gray-500">
                      開啟此功能後，搜尋將在您原本輸入的關鍵字之外額外搜尋相關聯的內容
                    </Switch.Description>
                  </div> */}
                  <Switch
                    checked={relatedWordsEnabled}
                    onChange={handleRelatedWords}
                    className={clsx(
                      relatedWordsEnabled ? 'bg-teal-500' : 'bg-gray-200',
                      'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2',
                    )}>
                    <span
                      aria-hidden="true"
                      className={clsx(
                        relatedWordsEnabled ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                </Switch.Group>
                {/* <SettingOutlined className="text-xl mr-2" />
                設定 */}
              </div>
            </div>
            {hasBetaMembership && (
              <div className="h-12 list-none text-md text-white font-bold tracking-wider cursor-pointer hover:text-blue-400">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <FireOutlined className="text-xl mr-2" />
                    測試內容
                  </div>
                  <Switch.Group
                    as="li"
                    className="flex items-center justify-between py-4">
                    <Switch
                      checked={betaEnabled}
                      onChange={handleBetaEnabled}
                      className={clsx(
                        betaEnabled ? 'bg-teal-500' : 'bg-gray-200',
                        'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2',
                      )}>
                      <span
                        aria-hidden="true"
                        className={clsx(
                          betaEnabled ? 'translate-x-5' : 'translate-x-0',
                          'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              </div>
            )}

            <li className="h-12 list-none text-md text-white font-bold tracking-wider cursor-pointer hover:text-blue-400">
              <div
                className="flex items-center"
                onClick={() => handleRoute('/subscribe')}>
                <ShoppingOutlined className="text-xl mr-2" />
                會員升級
              </div>
            </li>
            <li className="h-12 list-none text-md text-white font-bold tracking-wider cursor-pointer hover:text-blue-400">
              <div className="flex items-center" onClick={handleLogOut}>
                <LogoutOutlined className="text-xl mr-2" />
                登出
              </div>
            </li>
          </ul>
        )}
      </Menu>
    );
  } else {
    content = (
      <Menu>
        <MenuItem label="登入" handleClick={(e) => navigate(`/login`)} />
      </Menu>
    );
  }

  return (
    <div className="relative flex w-max ml-4" ref={navRef}>
      {content}
    </div>
  );
};

const createIDB = async (IDBVersion) => {
  await openDB('medicalHelper', IDBVersion, {
    upgrade: (db, oldVersion, newVersion, transaction) => {
      switch (oldVersion) {
        case 0:
          upgradeDBfromV0toV1();
        // falls through
        case 1:
          upgradeDBfromV1toV2();
          break;
        // case 2:
        //   upgradeDBfromV2toV3();
        // // falls through
        // case 3:
        //   upgradeDBfromV3toV4();
        //   break;
        default:
          console.error('unknown db version');
      }

      function upgradeDBfromV0toV1() {
        db.createObjectStore('medicines');
        db.createObjectStore('medicineSearch');
        db.createObjectStore('ICDSearch');
      }
      function upgradeDBfromV1toV2() {
        db.createObjectStore('treatmentSearch');
        db.createObjectStore('general');
      }
      function upgradeDBfromV2toV3() {}
    },
  });
};

export const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEditing = useSelector((state) => state.control.control);
  const authData = useSelector((state) => state.control.auth);
  const { IDB: IDBVersion } = useSelector((state) => state.control.dataVersion);
  const w768 = useMediaQuery('(min-width:768px)');
  const w1024 = useMediaQuery('(min-width:1024px)');
  const w1280 = useMediaQuery('(min-width:1280px)');
  const isPortrait = useMediaQuery('(orientation:portrait)');
  const h1080 = useMediaQuery('(min-height:1080px)');

  const getLayout = () => {
    if (isPortrait) {
      return 'layout_portrait';
    } else if (w1280) {
      return 'layout_screen';
    } else if (w1024 && h1080) {
      return 'layoutIpadProPortrait';
    } else if (w1024) {
      return 'layoutIpadLandscape';
    } else if (w768) {
      return 'layoutIpadPortrait';
    }
  };

  useUnload(isEditing.isEditing || isEditing.isEditingTable.status);

  useEffect(() => {
    dispatch(
      updateLayout({
        mediaQuery: {
          w768,
          w1024,
          w1280,
          h1080,
          isPortrait,
        },
        layout: getLayout(),
      }),
    );
  }, [w768, w1024, h1080, isPortrait]);

  useEffect(() => {
    monitorAuthState(async (user) => {
      if (user) {
        const { email, uid, emailVerified } = user;
        const customClaims = await getCustomClaims();
        const { role, expiration } = customClaims;
        // console.log('claim', customClaims);

        dispatch(loadAuth({ email, uid, role, expiration, emailVerified }));

        const expirationDate = moment(customClaims.expiration);
        const now = moment().valueOf();
        const duration = moment.duration(expirationDate.diff(now)).asHours();

        if (duration < 0) {
          notification.info({
            message: '藥方便已到期',
            description: `藥方便已於 ${expirationDate.format(
              'YYYY-MM-DD',
            )} 到期，請重新加值`,
            duration: 5,
          });
        } else if (duration < 72 && role === 'TRIAL') {
          notification.info({
            message: '藥方便即將到期',
            description: `藥方便即將於 ${Math.floor(
              duration,
            )} 小時後到期，立即加入會員繼續使用`,
            duration: 5,
          });
        } else if (duration < 72 && role === 'PREMIUM') {
          notification.info({
            message: '藥方便即將到期',
            description: `藥方便即將於 ${Math.floor(
              duration,
            )} 小時後到期，重新加值繼續使用`,
            duration: 5,
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    // load user data
    const loadData = async (uid) => {
      const userData = await fetchUserData(uid);
      dispatch(loadUserPreference(userData));
      dispatch(loadMedicineSearchHistory(userData));
      dispatch({ type: 'myMedicines/loadUserData', payload: userData });
      dispatch({ type: 'medicines/loadUserData', payload: userData });
      dispatch(loadICDSearchHistory(userData));
      dispatch(loadICDUserData(userData));
      dispatch(loadTreatmentSearchHistory(userData));
      dispatch(loadTreatmentUserData(userData));
      const dataVersion = await fetchDataVersion();
      dispatch(loadDataVersion(dataVersion));
    };

    if (authData.uid) {
      loadData(authData.uid);
      createIDB(IDBVersion);
    }
  }, [authData.uid, IDBVersion]);

  return (
    <Layout
      style={{
        minHeight: '100vh',
        zIndex: 1000,
        overflow: 'hidden',
      }}>
      <Header
        className="flex w-screen"
        style={{ position: 'fixed', zIndex: 50 }}>
        {/* logo color: #6EB4A9 */}
        <NavDrawer userEmail={authData.email} hidden={w1280} />
        <div className="hidden xl:flex xl:justify-start">
          <Logo />
          {authData.email && <Navigation />}
        </div>
        <div className="flex ml-auto">
          <ControlPanel userEmail={authData.email} />
          <RightNav userEmail={authData.email} />
        </div>
      </Header>
      <Content className="site-layout flex grow" style={{ marginTop: 64 }}>
        <div className="grow">{children}</div>
        <BackTop
          className={clsx(styles['ant-back-top'], styles['my-back-top'])}
        />
      </Content>
      <Footer
        className={clsx(
          styles['ant-layout-footer'],
          styles['my-footer'],
          'text-center',
        )}>
        <div className="flex justify-center items-center">
          <span className="px-4">
            ©2022 by Medical Helper All Rights Reserved
          </span>
          <span>|</span>
          <span
            className="px-4 cursor-pointer hover:text-slate-300"
            onClick={() => navigate('/terms')}>
            使用條款
          </span>
          <span>|</span>
          <span className="px-4">
            <span className="mr-3">與我聯繫:</span>
            <a href="mailto:medicalhelperweb@gmail.com?subject=我想進一步了解 [藥方便]">
              medicalhelperweb@gmail.com
            </a>
          </span>
        </div>
      </Footer>
    </Layout>
  );
};
