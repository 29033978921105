import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Menu, Button, notification, BackTop, Drawer } from 'antd';
import { useLocation } from 'react-router-dom';
import { saveMyNote } from '@/features/medicines/store/medicinesSlice';
import {
  savePickedTrees,
  savePickedMedicines,
  saveMedicineOrder,
} from '@/features/myMedicines/store/myMedicinesSlice';
import { saveICDUserData } from '@/features/ICDSearch/store/ICDSearchSlice';
import { saveTreatmentUserData } from '@/features/treatmentSearch/store/treatmentSearchSlice';
import {
  loadAuth,
  clearAuth,
  switchEdit,
  offEdit,
  updateLayout,
  updateSelectedMedicineNotes,
  updateSelectedTreatmentHospitalCol,
  saveUserPreference,
} from '@/features/controlPanel/store/controlSlice';
import ExportModal from './ExportModal';
import ImportModal from './ImportModal';
import FeedbackModal from './FeedbackModal';
import { ControlPanelPopoverMenu } from './ControlPanelPopoverMenu';
import { TreatmentSearch } from './../../treatmentSearch/routes/TreatmentSearch';
import { useBetaMode } from '@/hooks/useBetaMode';

const BetaNoteTypes = [
  {
    name: 'ATC代碼',
    key: 'ATCCode',
    css: 'col-span-3',
  },
  {
    name: '廠商',
    key: 'noteCompany',
    css: 'col-span-3',
  },
  {
    name: '統整',
    key: 'summary',
    children: [
      {
        name: '用法',
        key: 'usageSummary',
      },
      {
        name: '一般資料',
        key: 'blackSummary',
      },
      {
        name: '適應症',
        key: 'purpleSummary',
      },
      {
        name: '副作用',
        key: 'orangeSummary',
      },
      {
        name: '禁忌症',
        key: 'redSummary',
      },
      {
        name: '劑量相關',
        key: 'blueSummary',
      },
    ],
  },
  {
    name: '仿單',
    key: 'note',
    children: [
      {
        name: '用法',
        key: 'usageNote',
      },
      {
        name: '一般資料',
        key: 'blackNote',
      },
      {
        name: '適應症',
        key: 'purpleNote',
      },
      {
        name: '副作用',
        key: 'orangeNote',
      },
      {
        name: '禁忌症',
        key: 'redNote',
      },
      {
        name: '劑量相關',
        key: 'blueNote',
      },
    ],
  },
  {
    name: '補充',
    key: 'add',
    children: [
      {
        name: '用法',
        key: 'usageAdd',
      },
      {
        name: '一般資料',
        key: 'blackAdd',
      },
      {
        name: '適應症',
        key: 'purpleAdd',
      },
      {
        name: '副作用',
        key: 'orangeAdd',
      },
      {
        name: '禁忌症',
        key: 'redAdd',
      },
      {
        name: '劑量相關',
        key: 'blueAdd',
      },
    ],
  },
  {
    name: '健保給付條件',
    key: 'green',
    css: 'col-span-3',
  },
];

const NoteTypes = [
  {
    name: '一般資料',
    key: 'noteBlack',
  },
  {
    name: '適應症',
    key: 'notePurple',
  },
  {
    name: '副作用',
    key: 'noteOrange',
  },
  {
    name: '禁忌症',
    key: 'noteRed',
  },
  {
    name: '健保給付條件',
    key: 'noteGreen',
  },
  {
    name: '劑量相關',
    key: 'noteBlue',
  },
];

const HospitalTypes = [
  {
    name: '英文名稱',
    key: 'item - English',
  },
  {
    name: '給付規定',
    key: 'rule',
  },
  {
    name: '基層院所',
    key: 'clinic',
  },
  {
    name: '地區醫院',
    key: 'local',
  },
  {
    name: '區域醫院',
    key: 'region',
  },
  {
    name: '醫學中心',
    key: 'center',
  },
  {
    name: '其他',
    key: 'other',
  },
  {
    name: '健保點數',
    key: 'charge',
  },
];

export const ControlPanel = ({ userEmail, uid }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isExportOpen, setExportOpen] = useState(false);
  const [isImportOpen, setImportOpen] = useState(false);
  const [isContactOpen, setContactOpen] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({});
  const isEditing = useSelector((state) => state.control.control.isEditing);

  const { myNote, isSaving: medicinesIsSaving } = useSelector(
    (state) => state.medicines,
  );
  const {
    pickedTrees,
    pickedMedicines,
    medicineOrder,
    isSaving: myMydicinesIsSaving,
  } = useSelector((state) => state.myMedicines);
  const { w1440 } = useSelector((state) => state.control.mediaQuery);
  const notesSelectRef = useRef();
  const { selectedMedicineNotes, selectedTreatmentHospitalCol } = useSelector(
    (state) => state.control.userPreference,
  );
  const isTreatmentPage = pathname === '/treatment-search';
  const buttonSize = w1440 ? 'middle' : 'small';

  const isBetaMode = useBetaMode();

  const handleSave = async () => {
    setLoadings(true);

    const allDispatch = [
      dispatch(savePickedTrees(pickedTrees)),
      dispatch(savePickedMedicines(pickedMedicines)),
      dispatch(saveMedicineOrder(medicineOrder)),
      dispatch(saveMyNote(myNote)),
      dispatch(saveUserPreference()),
      dispatch(saveTreatmentUserData()),
      dispatch(saveICDUserData()),
    ];
    await Promise.all(allDispatch)
      .then((allResults) => {
        const isSaveSuccess = allResults.every(
          (result) => result?.meta?.requestStatus === 'fulfilled',
        );

        if (isSaveSuccess) {
          setLoadings(false);
          notification.success({
            message: '儲存結果',
            description: '已成功儲存',
            duration: 2,
          });
        } else {
          setLoadings(false);
          notification.error({
            message: '儲存結果',
            description: '儲存失敗',
            duration: 2,
          });
        }
      })
      .then(() => dispatch(offEdit()));
  };

  const switchEditMode = async () => {
    if (isEditing) {
      await dispatch({
        type: 'myMedicines/loadUserData',
        payload: currentUserData,
      });
    } else {
      setCurrentUserData(() =>
        JSON.parse(
          JSON.stringify({
            pickedTrees,
            pickedMedicines,
            medicineOrder,
          }),
        ),
      );
    }
    dispatch(switchEdit());
  };

  const handleMedicineNoteSelect = ({ e, key }) => {
    dispatch(
      updateSelectedMedicineNotes({
        [key]: e.target.checked,
      }),
    );
  };

  const handleTreatmentHospitalColSelect = ({ e, key }) => {
    dispatch(
      updateSelectedTreatmentHospitalCol({
        [key]: e.target.checked,
      }),
    );
  };

  return (
    <div className="flex 2xl:mr-20 lg:mr-8 gap-4 items-center">
      {userEmail && (
        <div className="flex gap-4 items-center">
          {isTreatmentPage && (
            <ControlPanelPopoverMenu
              handleSelect={handleTreatmentHospitalColSelect}
              size={buttonSize}
              menuData={HospitalTypes}
              selected={selectedTreatmentHospitalCol}
              title="處置類型"
            />
          )}
          <ControlPanelPopoverMenu
            handleSelect={handleMedicineNoteSelect}
            size={buttonSize}
            menuData={isBetaMode ? BetaNoteTypes : NoteTypes}
            selected={selectedMedicineNotes}
            title="備註類型"
            css={isBetaMode && '!w-[480px] grid grid-cols-3}'}
          />
          <Button
            type="primary"
            size={buttonSize}
            onClick={switchEditMode}
            style={
              isEditing
                ? {
                    backgroundColor: 'rgb(220 38 38)',
                    borderColor: 'rgb(220 38 38)',
                    transition: 'all 0.5s',
                  }
                : { transition: 'all 0.5s' }
            }>
            {isEditing ? '取消編輯' : '編輯'}
          </Button>
          <Button
            type="primary"
            size={buttonSize}
            loading={loadings}
            onClick={handleSave}>
            儲存
          </Button>
          <Button
            type="primary"
            size={buttonSize}
            onClick={() => setExportOpen(true)}>
            匯出
          </Button>
          <Button
            type="primary"
            size={buttonSize}
            onClick={() => setImportOpen(true)}>
            匯入
          </Button>
        </div>
      )}
      <Button
        type="primary"
        size={buttonSize}
        onClick={() => setContactOpen(true)}>
        與我聯繫
      </Button>
      {/* {uid && <UserRightNav userEmail={userEmail} />}
      {!uid && <RightNav />} */}

      <ExportModal isVisible={isExportOpen} setVisible={setExportOpen} />
      <ImportModal isVisible={isImportOpen} setVisible={setImportOpen} />
      <FeedbackModal isVisible={isContactOpen} setVisible={setContactOpen} />
    </div>
  );
};
