import { TableModal } from '@/components/Elements/Modal';
import { switchTableEdit } from '@/features/controlPanel/store/controlSlice';
import { updateMyNote } from '@/features/medicines/store/medicinesSlice';
import {
  addPickedMedicine,
  addPickedTree,
  removeAllPickedMedicines,
  removePickedMedicine,
  selectAllPickedMedicines,
} from '@/features/myMedicines/store/myMedicinesSlice';
import { useBetaMode } from '@/hooks/useBetaMode';
import {
  downloadInsuranceDetail,
  downloadPackageInsert,
} from '@/libs/firebase/storage';
import { FileImageOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  notification,
} from 'antd';
import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import './MedicineTable.css';

const WrapperRow = (props) => {
  const bgColor = props.children[0].props.record['table-color'];

  return (
    <tr
      {...props}
      className={clsx(
        'ant-table-row',
        !!bgColor && `my-row-${bgColor}`,
        props.className,
      )}
    />
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? <InputNumber /> : <Input.TextArea autoSize />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              // required: true,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const combineNote = (rawData, note) => {
  if (!rawData) return [];
  if (!note) return rawData;

  const combinedNote = rawData.map((medicine) => ({
    ...medicine,
    ...note?.[medicine.key],
  }));

  return combinedNote;
};

export const MedicineTable = React.memo(({ tableData }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const isCheckable = useSelector((state) => state.control.control.isEditing);
  const [editingKey, setEditingKey] = useState('');
  // const isAdmin = useSelector((state) => state.control.auth.role) === 'ADMIN';
  // const betaEnabled = useSelector(
  //   (state) => state.control.userPreference.betaEnabled,
  // );
  const isBetaMode = useBetaMode();
  const isEditingTable = useSelector(
    (state) => state.control.control.isEditingTable,
  );
  const pickedMedicines = useSelector(
    (state) => state.myMedicines.pickedMedicines,
  );
  const allMedicineKeys = tableData?.data?.map((medicine) => medicine.key);

  const myNote = useSelector((state) => state.medicines.myNote);
  const selected = useSelector(
    (state) => state.control.userPreference.selectedMedicineNotes,
  );
  const insuranceData = useSelector((state) => state.medicines.insurances);

  const medicineDataWithNote = useMemo(() => {
    return combineNote(tableData?.data, myNote);
  }, [tableData?.data, myNote]);
  const rowSelection = useMemo(
    () => ({
      getCheckboxProps: (record) => ({
        disabled: !isCheckable,
        // Column configuration not to be checked
        name: record.name,
      }),
      onSelect: (record, selected, selectedRows) => {
        const { medicineSection = {} } = record;
        const { key = '', section = '' } = medicineSection;

        if (selected) {
          dispatch(addPickedMedicine(record.key));
          // check whether medicineSection key in pickedTrees; if true, return. if false, append
          dispatch(addPickedTree({ key, section }));
        } else {
          dispatch(removePickedMedicine(record.key));
        }
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        const medicineRow =
          selectedRows.filter((medicine) => medicine !== undefined)[0] || {};
        const { medicineSection = {} } = medicineRow;
        const { key = '', section = '' } = medicineSection;

        if (selected) {
          dispatch(selectAllPickedMedicines(allMedicineKeys));

          dispatch(addPickedTree({ key, section }));
        } else {
          // TODO: remove picked tree from myMedicine page
          dispatch(removeAllPickedMedicines(allMedicineKeys));
        }
      },
      selectedRowKeys: pickedMedicines,
    }),
    [isCheckable, pickedMedicines],
  );

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    dispatch(switchTableEdit(record.name));
    form.setFieldsValue({
      code: '',
      status: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
    dispatch(switchTableEdit());
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      dispatch(updateMyNote({ [key]: row }));
      setEditingKey('');
      dispatch(switchTableEdit());
      //   const newData = [...data];
      //   const index = newData.findIndex((item) => key === item.key);

      //   if (index > -1) {
      //     const item = newData[index];
      //     newData.splice(index, 1, { ...item, ...row });
      //     setData(newData);
      //   } else {
      //     newData.push(row);
      //     setData(newData);
      //     setEditingKey('');
      // }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: () => <div className="text-center">健保碼</div>,
      dataIndex: 'key',
      width: '8%',
      shouldCellUpdate: false,
      render: (text, record, index) => {
        const hasInsuranceCode = !text.startsWith('----------');

        return (
          <div
            className="text-center"
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            }}>
            {hasInsuranceCode ? text : '------'}
            <br />
            <span className="text-blue-400">
              {selected['ATCCode'] && record['ATC-code']}
            </span>
          </div>
        );
      },
    },
    {
      title: () => <div className="text-center">代碼</div>,
      dataIndex: 'code',
      width: '7%',
      editable: true,
      render: (text, record) => (
        <div
          className="text-center"
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}>
          {text}
        </div>
      ),
    },
    {
      title: () => <div className="text-center">藥物名稱</div>,
      dataIndex: 'name',
      width: '14%',
      shouldCellUpdate: false,
      render: (text, record) => (
        <div className="text-center" style={{ whiteSpace: 'pre-line' }}>
          {text}
          <br />
          <span className="text-blue-400">
            {selected['noteCompany'] && record['note-company']}
          </span>
        </div>
      ),
      // render(text, record) {
      //   return {
      //     props: {},
      //     children: record['name']?.split('\n').map((line, idx) => (
      //       <div key={idx} className="text-red-400">
      //         {line}
      //       </div>
      //     )),
      //   };
      // },
    },
    {
      title: '成分',
      dataIndex: 'ingredients',
      width: '16%',
      shouldCellUpdate: false,
    },
    {
      title: '用法',
      dataIndex: 'usage',
      width: '10%',
      shouldCellUpdate: false,
      render: (text, record) => {
        const betaColumn = (
          <div>
            {selected['usageSummary'] && (
              <div className="">{record['usage-summary']}</div>
            )}
            {selected['usageNote'] && (
              <div className="">{record['usage-note']}</div>
            )}
            {selected['usageAdd'] && (
              <div className="">{record['usage-add']}</div>
            )}
          </div>
        );

        const column = <div>{record.usage}</div>;

        return isBetaMode ? betaColumn : column;
      },
    },
    {
      title: '健保給付與備註',
      dataIndex: 'note',
      width: '26%',
      shouldCellUpdate: false,
      render: (text, record) => {
        // console.log(record);
        const betaColumn = (
          <div style={{ whiteSpace: 'pre-line' }}>
            {selected['blackSummary'] && (
              <div className="">{record['black-summary']}</div>
            )}
            {selected['blackNote'] && (
              <div className="">{record['black-note']}</div>
            )}
            {selected['blackAdd'] && (
              <div className="">{record['black-add']}</div>
            )}
            {selected['purpleSummary'] && (
              <div className="text-purple-500">{record['purple-summary']}</div>
            )}
            {selected['purpleNote'] && (
              <div className="text-purple-500">{record['purple-note']}</div>
            )}
            {selected['purpleAdd'] && (
              <div className="text-purple-500">{record['purple-add']}</div>
            )}
            {selected['orangeSummary'] && (
              <div className="text-orange-500">{record['orange-summary']}</div>
            )}
            {selected['orangeNote'] && (
              <div className="text-orange-500">{record['orange-note']}</div>
            )}
            {selected['orangeAdd'] && (
              <div className="text-orange-500">{record['orange-add']}</div>
            )}
            {selected['redSummary'] && (
              <div className="text-red-500">{record['red-summary']}</div>
            )}
            {selected['redNote'] && (
              <div className="text-red-500">{record['red-note']}</div>
            )}
            {selected['redAdd'] && (
              <div className="text-red-500">{record['red-add']}</div>
            )}
            {selected['blueSummary'] && (
              <div className="text-blue-500">{record['blue-summary']}</div>
            )}
            {selected['blueNote'] && (
              <div className="text-blue-500">{record['blue-note']}</div>
            )}
            {selected['blueAdd'] && (
              <div className="text-blue-500">{record['blue-add']}</div>
            )}
            {selected['green'] && (
              <div className="text-green-500">
                {record['note-code']?.split('$')?.map((item) => {
                  if (item === '' || !insuranceData) return;

                  let insurance = insuranceData.find((insurance) => {
                    // console.log('code', insurance?.insurance_code, 'item', item);
                    return insurance?.insurance_code === item;
                  });

                  let content = (
                    <div key={item}>
                      <TableModal
                        key={item}
                        title={item}
                        content={insurance?.['content']}
                      />
                    </div>
                  );

                  if (insurance?.['note_code'] !== '') {
                    // console.log(insurance?.['note_code']);
                    content = (
                      <div key={item}>
                        <TableModal
                          key={item}
                          title={item}
                          content={insurance?.['content']}
                        />

                        {insurance?.['note_code'].split('$').map((code) => (
                          <a
                            onClick={() =>
                              downloadInsuranceDetail({
                                key: code,
                              })
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center text-green-500 hover:underline hover:text-green-400"
                            key={code}>
                            {code}
                          </a>
                        ))}
                      </div>
                    );
                  }

                  return content;
                })}
                {record['note-green']}
              </div>
            )}
          </div>
        );

        const column = (
          <div style={{ whiteSpace: 'pre-line' }}>
            {selected?.noteBlack && (
              <div className="">{record['note-black']}</div>
            )}
            {selected?.notePurple && (
              <div className="text-blue-500">{record['note-purple']}</div>
            )}
            {selected?.noteOrange && (
              <div className="text-blue-500">{record['note-orange']}</div>
            )}
            {selected?.noteRed && (
              <div className="text-red-500">{record['note-red']}</div>
            )}
            {selected?.noteGreen && (
              <div className="text-green-600">{record['note-green']}</div>
            )}
            {selected?.noteBlue && (
              <div className="text-blue-500">{record['note-blue']}</div>
            )}
          </div>
        );

        return isBetaMode ? betaColumn : column;
        //   console.log('column', isAdmin, betaEnabled);
        //   if (isAdmin) {
        //     if (betaEnabled) {
        //       return betaColumn;
        //     } else {
        //       return column;
        //     }
        //   } else {
        //     return column;
        //   }
      },
    },
    {
      title: () => <div className="text-center">狀態</div>,
      dataIndex: 'status',
      width: '10%',
      editable: true,
      render: (text, record) => (
        <div
          className="text-center"
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}>
          {text}
        </div>
      ),
    },
    {
      title: () => (
        <div className="text-center">
          <div>圖片</div>
          <div>仿單</div>
        </div>
      ),
      dataIndex: 'image',
      width: '5%',
      shouldCellUpdate: false,
      render: (text, record) => (
        <div className="flex flex-col justify-center items-center gap-2 text-xl">
          {record.url ? (
            <a
              href={record.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center">
              <FileImageOutlined />
            </a>
          ) : (
            <a
              onClick={() =>
                notification.info({
                  message: '此藥物無圖片',
                  duration: 2,
                })
              }
              className="flex items-center">
              <FileImageOutlined />
              {record.url}
            </a>
          )}
          <a
            onClick={() => downloadPackageInsert(record)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center">
            <InfoCircleOutlined />
          </a>
        </div>
      ),
    },

    {
      title: () => <div className="text-center">編輯</div>,
      dataIndex: 'operation',
      width: '5%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="flex flex-col justify-content gap-2 text-center">
            <Typography.Link onClick={() => save(record.key)}>
              儲存
            </Typography.Link>
            <Typography.Link onClick={cancel}>取消</Typography.Link>
          </span>
        ) : (
          <div className="text-center">
            <Typography.Link
              disabled={editingKey !== '' || isEditingTable.status}
              onClick={() => edit(record)}>
              編輯
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
            row: WrapperRow,
          },
        }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        onRow={(record) => {
          return {
            onMouseEnter: (event) => {
              // console.log(event.currentTarget.className);
              // event.target.classList.add('text-3xl');
            }, // 鼠标移入行
            onMouseLeave: (event) => {
              // event.target.classList.remove('text-3xl');
            },
          };
        }}
        bordered
        dataSource={medicineDataWithNote}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
    // content
  );
});

MedicineTable.displayName = 'MedicineTable';
