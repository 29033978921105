import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import store from '@/store/store';

// import { AuthProvider } from '@/libs/auth';
import { Button, Spin } from 'antd';
import { useEffect } from 'react';

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert">
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button
        className="mt-4"
        onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
};

// type AppProviderProps = {
//   children: React.ReactNode;
// };

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spin size="large" />
        </div>
      }>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <ReduxProvider store={store}>
            {/* <Notifications /> */}
            {/* <AuthProvider> */}
            <Router>{children}</Router>
            {/* </AuthProvider> */}
          </ReduxProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
