import { initializeApp } from 'firebase/app';
import { getAuth, useDeviceLanguage, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
export * from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: 'AIzaSyA7jcVzv9FZQJc5_tykZE4m3c64Ge0FokU',
  authDomain: 'medicine-search-1bb3e.firebaseapp.com',
  databaseURL:
    'https://medicine-search-1bb3e-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'medicine-search-1bb3e',
  storageBucket: 'medicine-search-1bb3e.appspot.com',
  messagingSenderId: '557498319549',
  appId: '1:557498319549:web:ce4f0000084a70964eadd4',
  measurementId: 'G-KJFHYK7J3K',
};

self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const auth = getAuth(firebaseApp);
useDeviceLanguage(auth);

export const database = getDatabase(firebaseApp);
// export const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider('6Len6f0hAAAAAHGN20bSvbyextpa5Uxz4OlkMZb0'),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true,
// });

if (location.hostname === 'localhost') {
  console.log('start firebase emulator');
  // Point to the RTDB emulator running on localhost.
  // connectDatabaseEmulator(database, 'localhost', 9000);
  // connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export default firebaseApp;
