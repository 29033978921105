import React from 'react';
import { Popover } from '@headlessui/react';
import clsx from 'clsx';

export const ControlPanelPopoverMenu = ({
  title,
  handleSelect,
  size,
  menuData,
  selected,
  css,
}) => {
  // console.log('selected', selected);
  return (
    <Popover className="relative">
      <Popover.Button
        className={clsx(
          'ant-btn ant-btn-primary',
          size === 'small' && 'ant-btn-sm',
          size === 'middle' && 'ant-btn-md',
        )}>
        {title}
      </Popover.Button>

      <Popover.Panel className="absolute z-10">
        <ul
          className={`absolute top-1 left-0 w-48 p-4 px-4 border-violet-800 rounded-lg bg-control-blue ${css}`}>
          {menuData.map((noteType) => (
            <li
              className={`flex flex-col justify-center mb-4 ${
                noteType.css || ''
              }`}
              key={noteType.key}>
              <div className="flex items-center">
                <input
                  id={noteType.key}
                  type="checkbox"
                  checked={selected[noteType.key]}
                  onChange={(e) => handleSelect({ e, key: noteType.key })}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  // className="h-4 w-4 rounded border border-gray-400 hover:border-blue-500 focus:ring-2 focus:ring-blue-500 checked:bg-blue-500"
                />
                <label
                  htmlFor={noteType.key}
                  className="ml-2 text-sm font-medium text-gray-300 dark:text-gray-300">
                  {noteType.name}
                </label>
              </div>
              {noteType?.children?.length > 0 && (
                <ul className="mt-4">
                  {noteType.children.map((child) => (
                    <li key={child.key} className={`flex mb-4`}>
                      <input
                        id={child.key}
                        type="checkbox"
                        checked={selected[child.key]}
                        onChange={(e) => handleSelect({ e, key: child.key })}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor={child.name}
                        className="ml-2 text-sm font-medium text-gray-300 dark:text-gray-300">
                        {child.name}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </Popover.Panel>
    </Popover>
  );
};
