import React, { useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router';
import { AutoComplete, Input } from 'antd';
import clsx from 'clsx';
import styles from './SearchBar.module.css';

export const SearchBar = ({
  inputValue,
  handleSearch,
  handleInput,
  disabled,
  mode,
}) => {
  const { pathname } = useLocation();

  return (
    <div
      className={clsx(
        'history-search-bar search-bar-customInput flex justify-center relative w-full',
      )}>
      <div className="w-full pr-2">
        <Input.Search
          size="large"
          value={inputValue}
          placeholder={disabled ? '' : '請輸入欲搜尋的關鍵字'}
          onChange={(e) => handleInput(e.target.value)}
          onSearch={handleSearch}
          enterButton
          className={clsx(
            pathname === '/icd-search' &&
              mode === 'ALL' &&
              styles['ICD-search'],
            pathname === '/icd-search' &&
              mode === 'MOST_USED' &&
              styles['ICD-search-mostUsed'],
            pathname === '/treatment-search' &&
              mode === 'ALL' &&
              styles['treatment-search'],
            pathname === '/treatment-search' &&
              mode === 'MOST_USED' &&
              styles['treatment-search-mostUsed'],
          )}
        />
      </div>
    </div>
  );
};
