import { Form, Input, InputNumber, Table, Typography } from 'antd';
import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { Popover } from '@headlessui/react';
import { CaretRightOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { arrayMoveImmutable } from 'array-move';
import { clsx } from 'clsx';
import {
  addPickedTreatment,
  removePickedTreatment,
  selectAllPickedTreatment,
  removeAllPickedTreatment,
  updateTreatmentNote,
  addCustomizedTreatment,
  removeCustomizedTreatment,
  updateTreatmentOrder,
} from '../store/treatmentSearchSlice';
import { switchTableEdit } from '@/features/controlPanel/store/controlSlice';
import { SegmentPopover } from './SegmentPopover';
import { downloadNoteCode } from '@/libs/firebase/storage';

const WrapperRow = (props) => {
  const bgColor = props.children[0].props.record['table-color'];

  return (
    <tr
      {...props}
      className={clsx(
        'ant-table-row',
        !!bgColor && `my-row-${bgColor}`,
        props.className,
      )}
    />
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? <InputNumber /> : <Input.TextArea autoSize />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              // required: true,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const combineNote = (rawData, note) => {
  if (!rawData) return [];
  if (!note) return rawData;

  const combinedNote = rawData.map((treatment) => ({
    ...treatment,
    ...note?.[treatment.code],
  }));

  return combinedNote;
};

export const TreatmentTable = ({ treatmentData }) => {
  const dispatch = useDispatch();
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const isEditMode = useSelector((state) => state.control.control.isEditing);
  const { pickedTreatment, myTreatmentNote, segments, mostUsed } = useSelector(
    (state) => state.treatmentSearch,
  );
  const { isEditingTable } = useSelector((state) => state.control.control);
  const { selectedTreatmentHospitalCol } = useSelector(
    (state) => state.control.userPreference,
  );
  const treatmentDataWithNote = useMemo(() => {
    return combineNote(treatmentData, myTreatmentNote);
  }, [treatmentData, myTreatmentNote]);
  const isEditing = (record) => record.code === editingKey;

  const edit = (record) => {
    dispatch(switchTableEdit(record.code));
    form.setFieldsValue({
      note: '',
      ...record,
    });
    setEditingKey(record.code);
  };

  const cancel = () => {
    setEditingKey('');
    dispatch(switchTableEdit());
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      dispatch(updateTreatmentNote({ [key]: row }));
      setEditingKey('');
      dispatch(switchTableEdit());
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleAddCustomizedTreatmentData = useCallback(
    (props) => {
      dispatch(addCustomizedTreatment(props));
    },
    [addCustomizedTreatment],
  );

  const handleRemoveCustomizedTreatmentData = useCallback(
    (props) => {
      dispatch(removeCustomizedTreatment(props));
    },
    [removeCustomizedTreatment],
  );

  const columns = [
    {
      title: '代碼',
      dataIndex: 'code',
      key: 'code',
      width: 60,
      render: (text) => <a>{text}</a>,
    },
    {
      title: '診療項目',
      dataIndex: 'item',
      key: 'item',
      width: 240,
      render: (text, record) => {
        return (
          <div
            className="flex flex-col"
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            }}>
            <span>{record['item - Chinese']}</span>
            <span>
              {selectedTreatmentHospitalCol['item - English'] &&
                record['item - English']}
            </span>
          </div>
        );
      },
    },
    {
      title: '給付規定',
      dataIndex: 'rule',
      key: 'rule',
      width: 360,
      render: (text, record) => (
        <div
          className=""
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}>
          {text}
          {record['note - code'] && (
            <div className="mt-4">
              {record['note - code'].split('$').map((code) => (
                <a
                  key={code}
                  onClick={() => downloadNoteCode({ key: code })}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center">
                  {code}
                </a>
              ))}
            </div>
          )}
        </div>
      ),
    },
    {
      title: (
        <div className="flex justify-center">
          基層
          <br />
          院所
        </div>
      ),
      dataIndex: 'clinic',
      key: 'clinic',
      width: 70,
      render: (text) => <div className="text-center">{text}</div>,
    },
    {
      title: (
        <div className="flex justify-center">
          地區
          <br />
          醫院
        </div>
      ),
      dataIndex: 'local',
      key: 'local',
      width: 70,
      render: (text) => <div className="text-center">{text}</div>,
    },
    {
      title: (
        <div className="flex justify-center">
          區域
          <br />
          醫院
        </div>
      ),
      dataIndex: 'region',
      key: 'region',
      width: 70,
      render: (text) => <div className="text-center">{text}</div>,
    },
    {
      title: (
        <div className="flex justify-center">
          醫學
          <br />
          中心
        </div>
      ),
      dataIndex: 'center',
      key: 'center',
      width: 70,
      render: (text) => <div className="text-center">{text}</div>,
    },
    {
      title: <div className="flex justify-center">其他</div>,
      dataIndex: 'other',
      key: 'other',
      width: 70,
    },
    {
      title: (
        <div className="flex justify-center">
          健保
          <br />
          點數
        </div>
      ),
      dataIndex: 'charge',
      key: 'charge',
      width: 95,
      render: (text) => <div className="text-center">{text}</div>,
    },
    {
      title: <div className="flex justify-center">類別</div>,
      dataIndex: 'segment',
      key: 'segment',
      width: 70,
      render: (text, record) => (
        // TODO SegmentPopover duplicated. move it to @/components
        // revise addCustomizedICD/removeCustomizedICD to addCustomizedItem/removeCustomizedItem
        <div className="flex justify-center">
          <SegmentPopover
            segmentData={segments}
            mostUsed={mostUsed}
            addCustomizedTreatment={handleAddCustomizedTreatmentData}
            removeCustomizedTreatment={handleRemoveCustomizedTreatmentData}
            index={record.code}
            disabled={!isEditMode}
            icon={
              <CaretRightOutlined
                className="text-teal-400"
                style={{ color: '#2dd4bf', fontSize: '24px' }}
              />
            }
          />
        </div>
      ),
    },
    {
      title: () => <div className="text-center">備註</div>,
      dataIndex: 'note',
      width: 180,
      editable: true,
      render: (text, record) => (
        <div
          className="text-center"
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}>
          {text}
        </div>
      ),
    },
    {
      title: () => <div className="text-center">編輯</div>,
      dataIndex: 'operation',
      width: '5%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="flex flex-col justify-content gap-2 text-center">
            <Typography.Link onClick={() => save(record.code)}>
              儲存
            </Typography.Link>
            <Typography.Link onClick={cancel}>取消</Typography.Link>
          </span>
        ) : (
          <div className="text-center">
            <Typography.Link
              disabled={editingKey !== '' || isEditingTable.status}
              onClick={() => edit(record)}>
              編輯
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const filteredColumns = columns.filter((col) => {
    const regularCols = ['code', 'item', 'segment', 'note', 'operation'];
    if (regularCols.includes(col.dataIndex)) {
      return true;
    }
    return selectedTreatmentHospitalCol[col.dataIndex];
  });

  const mergedColumns = filteredColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const rowSelection = useMemo(
    () => ({
      getCheckboxProps: (record) => ({
        disabled: !isEditMode,
        // Column configuration not to be checked
        name: record.name,
      }),
      onSelect: (record, selected, selectedRows) => {
        const { code } = record;
        if (selected) {
          dispatch(addPickedTreatment(code));
        } else {
          dispatch(removePickedTreatment(code));
        }
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        const selectedKeys = changeRows.map((row) => row?.code);
        if (selected) {
          dispatch(selectAllPickedTreatment(selectedKeys));
        } else {
          dispatch(removeAllPickedTreatment(selectedKeys));
        }
      },
      selectedRowKeys: pickedTreatment,
    }),
    [isEditMode, pickedTreatment],
  );

  return (
    <Form form={form} component={false}>
      <Table
        columns={mergedColumns}
        dataSource={treatmentDataWithNote}
        rowKey="code"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        components={{
          body: {
            cell: EditableCell,
            row: WrapperRow,
          },
        }}
      />
    </Form>
  );
};
