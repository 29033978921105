import { updateUserData } from '@/libs/firebase/database';
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

export const updateSelectedMedicineNotes = createAsyncThunk(
  'control/updateSelectedMedicineNotes',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const userPreference = state.control.userPreference;
    const uid = state.control.auth.uid;

    const { selectedMedicineNotes } = userPreference;

    const payloadKey = Object.keys(payload)[0];
    const parentKey = ['summary', 'note', 'add'];
    const isParentKey = parentKey.includes(payloadKey);
    let updatedSelectedMedcineNotes;

    // update preferences based on different key type
    if (isParentKey) {
      const capitalizedParentKey =
        payloadKey.charAt(0).toUpperCase() + payloadKey.slice(1);
      const regex = new RegExp(capitalizedParentKey + '$');
      const relatedKeys = Object.keys(selectedMedicineNotes).filter((key) =>
        regex.test(key),
      );
      const updatedRelatedKeys = relatedKeys.reduce((acc, cur) => {
        acc[cur] = payload[payloadKey];
        return acc;
      }, {});

      updatedSelectedMedcineNotes = {
        ...selectedMedicineNotes,
        ...updatedRelatedKeys,
        ...payload,
      };
    } else {
      updatedSelectedMedcineNotes = {
        ...selectedMedicineNotes,
        ...payload,
      };
    }

    // remove old selecting options
    // let oldOptions = [
    //   'noteBlack',
    //   'noteBlue',
    //   'noteGreen',
    //   'noteOrange',
    //   'notePurple',
    //   'noteRed',
    // ];
    // oldOptions.forEach((option) => delete updatedSelectedMedcineNotes[option]);

    const updatedUserPreference = {
      ...userPreference,
      selectedMedicineNotes: updatedSelectedMedcineNotes,
    };

    await updateUserData(uid, 'userPreference', updatedUserPreference);
    return updatedSelectedMedcineNotes;
  },
);

export const updateSelectedTreatmentHospitalCol = createAsyncThunk(
  'control/updateSelectedTreatmentHospitalCol',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const userPreference = state.control.userPreference;
    const uid = state.control.auth.uid;

    const { selectedTreatmentHospitalCol } = userPreference;
    const updatedSelectedTreatmentHospitalCol = {
      ...selectedTreatmentHospitalCol,
      ...payload,
    };
    const updatedUserPreference = {
      ...userPreference,
      selectedTreatmentHospitalCol: updatedSelectedTreatmentHospitalCol,
    };

    await updateUserData(uid, 'userPreference', updatedUserPreference);
    return updatedSelectedTreatmentHospitalCol;
  },
);

export const saveUserPreference = createAsyncThunk(
  'control/saveUserPreference',
  async (payload, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const state = getState();
    const userPreference = state.control.userPreference;
    const uid = state.control.auth.uid;
    const result = await updateUserData(uid, 'userPreference', userPreference);

    if (result instanceof Error) {
      return rejectWithValue('failed');
    }

    return payload;
  },
);

export const togglePaginationMode = createAsyncThunk(
  'treatmentSearch/togglePaginationMode',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;

    await updateUserData(uid, 'userPreference/onePageMode', payload);

    return payload;
  },
);

export const controlSlice = createSlice({
  name: 'control',
  initialState: {
    auth: {
      email: null,
      uid: null,
      role: null,
      expiration: null,
      emailVerified: null,
      // currently open beta for all user, implement real membership checking fucntion when really needed in the future
      hasBetaMembership: true,
    },
    control: {
      isEditing: false,
      isEditingTable: {
        status: false,
        editingRowName: undefined,
      },
      saveCount: 0,
    },
    userPreference: {
      selectedMedicineNotes: {
        ATCCode: true,
        noteCompany: true,
        summary: true,
        usageSummary: true,
        blackSummary: true,
        purpleSummary: true,
        orangeSummary: true,
        redSummary: true,
        blueSummary: true,
        usageNote: true,
        blackNote: true,
        purpleNote: true,
        orangeNote: true,
        redNote: true,
        blueNote: true,
        usageAdd: true,
        blackAdd: true,
        purpleAdd: true,
        orangeAdd: true,
        redAdd: true,
        blueAdd: true,
        green: true,
        // previous
        noteBlack: true,
        noteBlue: true,
        noteGreen: true,
        noteOrange: true,
        notePurple: true,
        noteRed: true,
      },
      selectedTreatmentHospitalCol: {
        'item - English': true,
        rule: true,
        clinic: true,
        local: true,
        region: true,
        center: true,
        other: true,
        charge: true,
      },
      relatedWordsEnabled: true,
      ICDWithDot: true,
      betaEnabled: true,
      onePageMode: false,
    },
    dataVersion: {},
    relatedWords: {},
    layout: undefined,
    mediaQuery: {},
    isSaving: {
      userPreference: false,
    },
    error: null,
  },
  reducers: {
    loadAuth: (state, action) => {
      state.auth = { ...state.auth, ...action.payload };
    },
    clearAuth: (state) => {
      state.auth = { email: null, uid: null };
    },
    loadDataVersion: (state, action) => {
      state.dataVersion = action.payload;
    },
    loadUserPreference: (state, action) => {
      if (action.payload?.userPreference) {
        state.userPreference = {
          ...state.userPreference,
          ...action.payload?.userPreference,
        };
      }
    },
    loadRelatedWords: (state, action) => {
      state.relatedWords = action.payload;
    },
    switchEdit: (state) => {
      state.control.isEditing = !state.control.isEditing;
    },
    switchRelatedWords: (state) => {
      state.userPreference.relatedWordsEnabled =
        !state.userPreference.relatedWordsEnabled;
    },
    switchICDDot: (state) => {
      state.userPreference.ICDWithDot = !state.userPreference.ICDWithDot;
    },
    switchBeta: (state) => {
      state.userPreference.betaEnabled = !state.userPreference.betaEnabled;
    },
    offEdit: (state) => {
      state.control.isEditing = false;
    },
    switchTableEdit: (state, action) => {
      state.control.isEditingTable.status =
        !state.control.isEditingTable.status;
      state.control.isEditingTable.editingRowName = action.payload;
    },
    updateLayout: (state, action) => {
      state.mediaQuery = action.payload.mediaQuery;
      state.layout = action.payload.layout;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSelectedMedicineNotes.fulfilled, (state, action) => {
      state.userPreference.selectedMedicineNotes = action.payload;
    });
    builder.addCase(
      updateSelectedTreatmentHospitalCol.fulfilled,
      (state, action) => {
        state.userPreference.selectedTreatmentHospitalCol = action.payload;
      },
    );
    builder.addCase(saveUserPreference.rejected, (state, action) => {
      state.isSaving.userPreference = false;
    });
    builder.addCase(saveUserPreference.pending, (state, action) => {
      state.isSaving.userPreference = true;
    });
    builder.addCase(saveUserPreference.fulfilled, (state, action) => {
      state.isSaving.userPreference = false;
    });
    builder.addCase(togglePaginationMode.pending, (state, action) => {
      state.isSaving.userPreference = true;
    });
    builder.addCase(togglePaginationMode.fulfilled, (state, action) => {
      state.userPreference.onePageMode = action.payload;
      state.isSaving.userPreference = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  loadAuth,
  clearAuth,
  loadDataVersion,
  loadUserPreference,
  loadRelatedWords,
  switchEdit,
  switchRelatedWords,
  switchICDDot,
  switchBeta,
  offEdit,
  switchTableEdit,
  updateLayout,
} = controlSlice.actions;

export default controlSlice.reducer;
