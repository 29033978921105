import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { updateUserData } from '@/libs/firebase/database';
import { notification } from 'antd';

export const saveTreatmentUserData = createAsyncThunk(
  'treatmentSearch/saveTreatmentUserData',
  async (payload, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const state = getState();
    const uid = state.control.auth.uid;
    const {
      searchControlPanel = [],
      segments = [],
      mostUsed = [],
      mostUsedOrder = {},
      pickedTreatment = [],
      myTreatmentNote = {},
    } = state.treatmentSearch;
    const { segmentShortcuts } = searchControlPanel;

    const treatmentUserData = {
      segmentShortcuts,
      segments,
      mostUsed,
      mostUsedOrder,
      pickedTreatment,
      myTreatmentNote,
    };

    const result = await updateUserData(
      uid,
      'treatmentUserData',
      treatmentUserData,
    );

    if (result instanceof Error) {
      return rejectWithValue('failed');
    }

    return payload;
  },
);

export const addSearchKeyword = createAsyncThunk(
  'treatmentSearch/addSearchKeyword',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;
    let newKeywords = state.treatmentSearch.searchHistory.filter(
      (keyword) => keyword !== payload,
    );
    newKeywords.unshift(payload);

    if (newKeywords.length > 9) {
      newKeywords = newKeywords.slice(0, 9);
    }

    await updateUserData(uid, 'treatmentSearchHistory', newKeywords);

    return newKeywords;
  },
);

export const removeSearchKeyword = createAsyncThunk(
  'treatmentSearch/removeSearchKeyword',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;

    let newSearchHistory = state.treatmentSearch.searchHistory.filter(
      (keyword) => keyword !== payload,
    );

    await updateUserData(uid, 'treatmentSearchHistory', newSearchHistory);

    return newSearchHistory;
  },
);

export const updateSearchKeyword = createAsyncThunk(
  'treatmentSearch/updateSearchKeyword',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const uid = state.control.auth.uid;

    let newSearchHistory = payload;

    await updateUserData(uid, 'treatmentSearchHistory', newSearchHistory);

    return newSearchHistory;
  },
);

export const treatmentSearchSlice = createSlice({
  name: 'treatmentSearch',
  initialState: {
    searchHistory: [],
    isSaving: false,
    searchControlPanel: {
      mode: 'ALL',
      currentSegment: {
        parentSegment: '',
        childSegment: '',
      },
      segmentShortcuts: [],
    },
    sections: [],
    segments: [],
    mostUsed: [],
    mostUsedOrder: {},
    pickedTreatment: [],
    myTreatmentNote: {},
  },
  reducers: {
    loadTreatmentSearchHistory: (state, action) => {
      state.searchHistory = action.payload?.treatmentSearchHistory || [];
    },
    loadTreatmentUserData: (state, action) => {
      const {
        segmentShortcuts,
        segments,
        mostUsed,
        mostUsedOrder,
        pickedTreatment,
        myTreatmentNote,
      } = action.payload.treatmentUserData || {};

      const defaultShortcuts = new Array(20).fill('').map((i, idx) => ({
        index: idx,
        parentSegment: null,
        childSegment: null,
      }));

      state.searchControlPanel.segmentShortcuts =
        segmentShortcuts || defaultShortcuts;

      state.segments = segments || [];
      state.mostUsed = mostUsed || [];
      state.mostUsedOrder = mostUsedOrder || {};
      state.pickedTreatment = pickedTreatment || [];
      state.myTreatmentNote = myTreatmentNote || {};
    },
    setMode: (state, action) => {
      state.searchControlPanel.mode = action.payload;
    },
    setParentSegment: (state, action) => {
      state.searchControlPanel.currentSegment.parentSegment = action.payload;
    },
    setChildSegment: (state, action) => {
      state.searchControlPanel.currentSegment.childSegment = action.payload;
    },
    addParentSegment: (state, action) => {
      const parent = action.payload;
      const segments = current(state.segments);
      const index = segments.findIndex((segment) => segment.title === parent);

      if (index >= 0) {
        return notification.warning({
          message: '名稱重複',
          description: `${parent}已存在，請改用其他名稱`,
          duration: 2,
        });
      }

      const currentSegmentLength = segments.length;

      state.segments.push({
        title: parent,
        sortingOrder: currentSegmentLength,
        childSegments: [],
      });
    },
    addChildSegment: (state, action) => {
      const child = action.payload;
      const segments = current(state.segments);
      const { parentSegment } = current(
        state.searchControlPanel.currentSegment,
      );

      if (!parentSegment) {
        return;
      }

      const index = segments.findIndex(
        (segment) => segment.title === parentSegment,
      );

      if (index < 0) {
        return;
      }

      const pSegment = current(state.segments[index]);

      if ('childSegments' in pSegment) {
        const childSegmentsLength = segments[index]?.childSegments?.length;

        state.segments[index].childSegments.push({
          title: child,
          sortingOrder: childSegmentsLength,
          relatedTreatment: { data: [], order: [] },
        });
      } else {
        state.segments[index] = {
          ...pSegment,
          childSegments: [
            {
              title: child,
              sortingOrder: 0,
              relatedTreatment: { data: [], order: [] },
            },
          ],
        };
      }
    },
    updateSegments: (state, action) => {
      const { parentSegment } = current(
        state.searchControlPanel.currentSegment,
      );
      const { data, type } = action.payload;

      if (type === 'child') {
        state.segments.find(
          (segment) => segment.title === parentSegment,
        ).childSegments = data;
      } else {
        state.segments = data;
      }
    },
    removeSegment: (state, action) => {
      const { parentSegment } = current(
        state.searchControlPanel.currentSegment,
      );
      const { segment: title, type } = action.payload;

      if (type === 'child') {
        const segments = current(state.segments);
        const index = segments.findIndex(
          (pSegment) => pSegment.title === parentSegment,
        );
        const filteredSegments = segments[index].childSegments.filter(
          (segment) => {
            return segment.title !== title;
          },
        );

        state.segments[index].childSegments = filteredSegments;
      } else {
        const segments = current(state.segments);
        const index = segments.findIndex(
          (pSegment) => pSegment.title === parentSegment,
        );
        const filteredSegments = segments.filter(
          (segment) => segment.title !== title,
        );
        state.segments = filteredSegments;
      }
    },
    updateSegmentShortcut: (state, action) => {
      const { parentSegment, childSegment, index } = action.payload;
      state.searchControlPanel.segmentShortcuts[index] = {
        index,
        parentSegment,
        childSegment,
      };
    },
    addPickedTreatment: (state, action) => {
      state.pickedTreatment = [...state.pickedTreatment, action.payload];
    },
    removePickedTreatment: (state, action) => {
      state.pickedTreatment = state.pickedTreatment.filter(
        (key) => key !== action.payload,
      );
    },
    selectAllPickedTreatment: (state, action) => {
      const set = new Set([...state.pickedTreatment, ...action.payload]);
      state.pickedTreatment = Array.from(set);
    },
    removeAllPickedTreatment: (state, action) => {
      state.pickedTreatment = state.pickedTreatment.filter(
        (key) => !action.payload.includes(key),
      );
    },
    updateTreatmentNote: (state, action) => {
      const { payload } = action;

      const updatedTreatmentNote = {
        ...state.myTreatmentNote,
        ...payload,
      };

      state.myTreatmentNote = updatedTreatmentNote;
    },
    addCustomizedTreatment: (state, action) => {
      const { type, parent, child, code } = action.payload;

      if (type === 'MOST_USED') {
        state.mostUsed.push(code);
      } else {
        const parentIndex = current(state.segments).findIndex(
          (pSegment) => pSegment.title === parent,
        );
        const childIndex = current(
          state.segments[parentIndex],
        ).childSegments.findIndex((cSegment) => cSegment.title === child);

        const childSegment = current(
          state.segments[parentIndex].childSegments[childIndex],
        );

        if ('relatedTreatment' in childSegment) {
          state.segments[parentIndex].childSegments[
            childIndex
          ].relatedTreatment.data.push(code);
        } else {
          state.segments[parentIndex].childSegments[childIndex] = {
            ...childSegment,
            relatedTreatment: { data: [code] },
          };
        }
      }
    },
    removeCustomizedTreatment: (state, action) => {
      const { type, parent, child, code } = action.payload;

      if (type === 'MOST_USED') {
        const filteredTreatmentData = current(state.mostUsed).filter(
          (treatment) => treatment !== code,
        );
        const updatedTreatmentOrder = { ...current(state.mostUsedOrder) };

        if (code in updatedTreatmentOrder) {
          delete updatedTreatmentOrder[code];
        }

        state.mostUsed = filteredTreatmentData;
        state.mostUsedOrder = updatedTreatmentOrder;
      } else {
        const parentIndex = current(state.segments).findIndex(
          (pSegment) => pSegment.title === parent,
        );
        const childIndex = current(
          state.segments[parentIndex],
        ).childSegments.findIndex((cSegment) => cSegment.title === child);
        const relatedTreatment = current(
          state.segments[parentIndex].childSegments[childIndex]
            .relatedTreatment,
        );

        const filteredTreatmentData = relatedTreatment.data.filter(
          (treatment) => treatment !== code,
        );

        state.segments[parentIndex].childSegments[
          childIndex
        ].relatedTreatment.data = filteredTreatmentData;

        // delete treatment code in order if it has
        if ('order' in relatedTreatment) {
          const order = {
            ...current(
              state.segments[parentIndex].childSegments[childIndex]
                .relatedTreatment.order,
            ),
          };

          if (code in order) {
            delete order[code];

            state.segments[parentIndex].childSegments[
              childIndex
            ].relatedTreatment.order = order;
          }
        }
      }
    },
    updateTreatmentOrder: (state, action) => {
      const { mode, parent, child, content } = action.payload;
      // TODO: update from icd10 to treatment key

      if (mode === 'MOST_USED') {
        state.mostUsedOrder = content;
      } else {
        const parentIndex = current(state.segments).findIndex(
          (pSegment) => pSegment.title === parent,
        );
        const childIndex = current(
          state.segments[parentIndex],
        ).childSegments.findIndex((cSegment) => cSegment.title === child);

        const relatedTreatment = current(
          state.segments[parentIndex].childSegments[childIndex]
            .relatedTreatment,
        );
        state.segments[parentIndex].childSegments[childIndex].relatedTreatment =
          { ...relatedTreatment, order: content };
      }
    },
    updateSections: (state, action) => {
      state.sections = action.payload.sections;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveTreatmentUserData.pending, (state, action) => {
      state.isSaving = true;
    });
    builder.addCase(saveTreatmentUserData.rejected, (state, action) => {
      state.isSaving = false;
    });
    builder.addCase(saveTreatmentUserData.fulfilled, (state, action) => {
      state.isSaving = false;
    });
    builder.addCase(addSearchKeyword.pending, (state, action) => {
      state.isSaving = true;
    });
    builder.addCase(addSearchKeyword.fulfilled, (state, action) => {
      state.searchHistory = action.payload;
      state.isSaving = false;
    });
    builder.addCase(removeSearchKeyword.pending, (state, action) => {
      state.isSaving = true;
    });
    builder.addCase(removeSearchKeyword.fulfilled, (state, action) => {
      state.searchHistory = action.payload;
      state.isSaving = false;
    });
    builder.addCase(updateSearchKeyword.pending, (state, action) => {
      state.isSaving = true;
    });
    builder.addCase(updateSearchKeyword.fulfilled, (state, action) => {
      state.searchHistory = action.payload;
      state.isSaving = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  loadTreatmentSearchHistory,
  loadTreatmentUserData,
  setMode,
  setParentSegment,
  setChildSegment,
  addParentSegment,
  addChildSegment,
  updateSegments,
  removeSegment,
  updateSegmentShortcut,
  addPickedTreatment,
  removePickedTreatment,
  selectAllPickedTreatment,
  removeAllPickedTreatment,
  updateTreatmentNote,
  addCustomizedTreatment,
  removeCustomizedTreatment,
  updateTreatmentOrder,
  updateSections,
} = treatmentSearchSlice.actions;

export default treatmentSearchSlice.reducer;
