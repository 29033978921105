import React from 'react';
import clsx from 'clsx';
import styles from './Menu.module.css';

export const Menu = ({ children }) => {
  return (
    <ul
      className={clsx(
        styles['menu'],
        'w-full xl:w-auto flex flex-col xl:flex-row items-center mb-0',
      )}>
      {children}
    </ul>
  );
};
