import React from 'react';
import { Helmet } from 'react-helmet-async';

// type HeadProps = {
//   title?: string;
//   description?: string;
// };

export const Head = ({ title = '', description = '' }) => {
  return (
    <Helmet
      title={title ? `${title} | 藥方便` : undefined}
      defaultTitle="藥方便">
      <meta name="description" content={description} />
    </Helmet>
  );
};
