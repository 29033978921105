import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Form, Input } from 'antd';
import moment from 'moment';
import { sendFeedback, sendVisitorFeedback } from '@/libs/firebase/database';

const { TextArea } = Input;

const FeedbackModal = ({ isVisible, setVisible }) => {
  const [loading, setLoading] = useState(false);
  const uid = useSelector((state) => state.control.auth.uid);
  const [form] = Form.useForm();

  // const resetForm = () => {
  //   form.setFieldsValue({ name: '' });
  //   form.setFieldsValue('contactMessage', '456');
  //   form.setFieldsValue('contactInfo', '789');
  // };

  const handleOk = () => {
    const updateData = async () => {
      const contactData = {
        name: form.getFieldValue('name'),
        message: form.getFieldValue('contactMessage'),
        contactInfo: form.getFieldValue('contactInfo'),
        time: moment().valueOf(),
        uid,
      };

      if (uid) {
        await sendFeedback(uid, { ...contactData, uid })
          .then(() => {
            form.setFieldsValue({
              name: '',
              contactMessage: '',
              contactInfo: '',
            });
          })
          .then(() => {
            setLoading(false);
            setVisible(false);
          })
          .catch((err) => console.log(err));
      } else {
        await sendVisitorFeedback(contactData)
          .then(() => {
            form.setFieldsValue({
              name: '',
              contactMessage: '',
              contactInfo: '',
            });
          })
          .then(() => {
            setLoading(false);
            setVisible(false);
          })
          .catch((err) => console.log(err));
      }
    };
    setLoading(true);
    updateData();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      open={isVisible}
      title="與我聯繫"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}>
          送出
        </Button>,
      ]}>
      <Form layout="vertical" form={form}>
        <Form.Item name="name" label="稱呼方式">
          <Input />
        </Form.Item>
        <Form.Item name="contactMessage" label="意見反饋">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="contactInfo" label="聯繫方式">
          <TextArea rows={2} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FeedbackModal;
